@import "../../styles/variables.scss";

.profile-page {
  height: calc(100vh - 4vw);
  margin-top: 4vw !important;
  .user-details-container {
    .user-details-table-container {
      margin-top: 8%;
      table {
        width: 80%;
        td {
          font-size: 0.8vw;
          padding: 1%;
        }
      }
      .logout-btn {
        color: #fff;
        background: #ff5252;
        padding: 1%;
        border: none;
        width: 10vw;
        height: 2vw;
        font-size: 0.8vw;
        text-align: center;
        border-radius: 0.5vw;
        margin-top: 2%;
      }
      .home-btn {
        color: #000;
        background: $yellow;
        padding: 1%;
        border: none;
        width: 10vw;
        height: 2vw;
        font-size: 0.8vw;
        text-align: center;
        border-radius: 0.5vw;
        margin-top: 2%;
        margin-right: 2%;
      }
    }
    .user-details-heading {
      font-size: 2vw;
      margin: 0;
      padding-bottom: 2%;
    }
    .user-details-img-container {
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin-top: 8%;
      align-items: center;
      .user-img {
        width: 5vw;
        height: 5vw;
        margin-bottom: 5%;
        border-radius: 10vw;
      }
    }
    margin-top: 2%;
    height: calc(100% - 4vw);
    width: calc(100% - 4vw);
    background: $white;
    border: 0.2vw solid $blue;
    border-radius: 0.8vw;
  }
}

@import "../../styles/variables.scss";

.reports-page {
  height: calc(100vh - 4vh);
  margin-top: 4vw !important;
  display: flex;
  .reportMenuContainer {
    width: 20vw;
    min-width: 16.66vw;
  }
  .reportCollapseContainer {
    width: 10vw;
    .k-panelbar
      .k-panelbar-group
      > .k-panelbar-item.k-level-1
      .k-child-animation-container
      .k-link {
      padding-left: 1vw;
      background: #b8e2ea;
      overflow: auto;
      overflow-x: scroll;
    }
  }
  .backButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #e7f5f8;
    .k-button-link-info:focus,
    .k-button-link-info.k-focus {
      box-shadow: 0 0 0 0;
    }
    .backButton {
      padding: 0.2vw;
      margin: 0.5vw;
      width: 5vw;
      align-self: end;
      color: $darkBlue;
    }
  }
  .accordian-container {
    height: calc(100vh - 4vw);
    overflow: auto;
    margin: 0;
    padding: 0;
    background: white;
    .accordian-container {
      height: inherit;
      z-index: 0;
      .search-width {
        width: 16.66vw;
      }
      .search-collapse-width {
        width: 9.1vw;
      }
      .search-container {
        position: fixed;
        background-color: $menuChild;
        resize: horizontal;
        z-index: 1;
        padding: 1vh 0;
        border-bottom: 0.1vh solid #fff;
        display: flex;
        align-items: center;
        .search-icon {
          width: 1.5vw;
          height: 1.5vw;
          margin-left: 1vw !important;
          min-width: 0;
        }
        .input-field {
          border: 0;
          font-size: 0.8vw;
        }
        .input-field:focus-visible {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
  .reports-container {
    padding: 0% 1%;
    margin: 1% 0;
    height: 93%;
    width: 100%;
    overflow: auto;
    .iframe-container {
      height: 77vh;
      padding: 0 1%;
      background: white;
      iframe {
        width: 100%;
        border: 0.1vw solid #d9d9d9;
        background: #fff;
      }
    }
  }
}

.screen-container {
  height: 100%;
  background: #fff;
  overflow: hidden;

  .separator {
    display: flex;
    border: 0.1px solid #c4c4c4;
    flex: 1 1;
    margin-bottom: 1vh;
    margin-right: 12px;
  }

  .header-container {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .header-link-container {
        display: flex;
        align-items: center;
      }
    }
  }
  .footer-container {
    position: fixed;
    display: flex;
    width: 82vw;
    padding-bottom: 1vh;
    flex-direction: column;
    bottom: 6vh;
    .footer-title {
      font-size: 0.7vw;
      font-weight: bold;
      text-align: end;
    }
  }
}
.reconciliationContainer {
  height: 100%;
  width: 100%;
  .report-container {
    height: 94.3%;
    width: 100%;
    background-color: white;
    iframe {
      border-width: 1px;
    }
  }
  .backButtonContainer {
    background-color: white;
    display: flex;
    justify-content: flex-end;
    padding-right: 2vw;
    .backButton {
      padding: 0.2vw;
      margin: 0.5vw;
    }
  }
}

.user-management-screen-container {
  width: 100%;
  height: 99%;
  .links-row {
    margin: 1% auto;
    div {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      .underline {
        text-decoration: underline;
        text-decoration-color: $yellow;
        text-decoration-thickness: 0.3vw;
      }
      h4 {
        cursor: pointer;
        color: $white;
      }
    }
  }
  .content-container {
    height: 99%;
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      max-height: 78vh;
      height: auto;
      overflow: auto;
      padding: 0;
      border: 0;
      .title {
        color: #404040;
        text-transform: uppercase;
        font-size: 0.8vw;
        font-weight: 700;
      }
      .export-link {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1%;
        margin: 0;
        font-weight: bolder;
        cursor: pointer;
        color: #003c88;
        &-img {
          width: 1vw;
          height: 1vw;
        }
      }
      .k-label {
        display: flex;
        flex-flow: row nowrap;
        font-size: 0.8vw;
        font-weight: lighter;
        margin: 0;
        color: #404040;
        margin-bottom: 1vw;
      }
      .manual-data-upload {
        height: 60vh;
        &-filters {
          padding: 0 0 4%;
          display: flex;
          align-items: center;
          .filter {
            padding: 0 2%;
            .site-name {
              text-transform: capitalize;
              color: #6e6e6e;
            }
          }
          .k-datepicker {
            border-radius: 0;
            .k-input-button {
              padding: 2% 4%;
              border: none;
            }
            input {
              padding: 0.6vw;
            }
          }
          .k-input-value-text {
            font-size: 1vw;
          }
        }
        &-table {
          &-header {
            display: flex;
            align-items: center;
            background-color: $grey;
            color: $white;
            padding: 1%;
            margin: 0;
          }
          &-buttons {
            display: flex;
            justify-content: flex-end;
            margin: 2% 0;
            font-size: 0.8vw;
            font-weight: bold;
            .button {
              width: 8vw;
              padding: 1%;
              outline: none;
              border: none;
              border-radius: 0;
            }
            &-reset {
              background: $lightGrey;
            }
            &-submit {
              margin-left: 1%;
              background: $yellow;

              &:disabled {
                opacity: 0.5;
              }
            }
            &-fetch-site-data {
              margin-top: 2vw;
              padding: 0 10%;
              background: $yellow;
              height: 2.8vw;
              border-radius: 0;
            }
          }
          .help-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15vw;
            color: $black;
            border: 1px solid $lightGrey;
            margin: 0;
          }
        }
      }
    }
  }
}

.k-popup {
  max-height: 25vh;
}

.accordian-container::-webkit-scrollbar {
  display: none;
}
.content-container::-webkit-scrollbar {
  display: none;
}

.input-field-container {
  display: flex;
  flex-direction: column;
  padding: 1%;
  span {
    margin: 0.5% 0;
  }
  .field {
    input,
    select {
      width: 70%;
      padding: 1%;
    }
    input:focus {
      border: none;
    }
  }
}

.user-management-screen-btn-reset {
  text-align: center;
  padding: 1% 2%;
  margin: 2% 0.8%;
  width: 8vw;
  font-size: 0.8vw;
  font-weight: bold;
  background: $lightGrey;
  outline: none;
  border: none;
}
.user-management-screen-btn-submit {
  text-align: center;
  padding: 1% 2%;
  margin: 2% 0.8%;
  width: 8vw;
  font-size: 0.8vw;
  font-weight: bold;
  background: $yellow;
  outline: none;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
}

.user-management-edit-user-screen-btn-submit {
  background: $yellow !important;
  margin: 2%;
}

.details-container-dropdown {
  .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 0 !important;
  }
}

.k-animation-container {
  .k-searchbox {
    input {
      padding: 0 !important;
    }
  }
}

.site-info-container {
  width: 100%;
  height: 100%;
  .links-row {
    margin: 1% auto;
    div {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      .underline {
        text-decoration: underline;
        text-decoration-color: $yellow;
        text-decoration-thickness: 0.3vw;
      }
      h4 {
        cursor: pointer;
        color: $white;
      }
    }
  }
  .content-container {
    height: inherit;
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0 2%;
      .title {
        margin: 2% 0% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 1vw;
        font-weight: bold;
      }
      .export-link {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1%;
        margin: 0;
        font-weight: bolder;
        cursor: pointer;
        color: #003c88;
        &-img {
          width: 1vw;
          height: 1vw;
        }
      }
      .k-label {
        display: flex;
        flex-flow: row nowrap;
        font-size: 0.8vw;
        font-weight: lighter;
        margin: 0;
        color: #404040;
        margin-bottom: 1vw;
      }
      .manual-data-upload {
        height: 60vh;
        &-filters {
          padding: 0 0 4%;
          display: flex;
          align-items: center;
          .filter {
            padding: 0 2%;
            .site-name {
              text-transform: capitalize;
              color: #6e6e6e;
            }
          }
          .k-datepicker {
            border-radius: 0;
            .k-input-button {
              padding: 2% 4%;
              border: none;
            }
            input {
              padding: 0.6vw;
            }
          }
          .k-input-value-text {
            font-size: 1vw;
          }
        }
        &-table {
          &-header {
            display: flex;
            align-items: center;
            background-color: $grey;
            color: $white;
            padding: 1%;
            margin: 0;
          }
          &-buttons {
            display: flex;
            justify-content: flex-end;
            margin: 2% 0;
            font-size: 0.8vw;
            font-weight: bold;
            .button {
              width: 8vw;
              padding: 1%;
              outline: none;
              border: none;
              border-radius: 0;
            }
            &-reset {
              background: $lightGrey;
            }
            &-submit {
              margin-left: 1%;
              background: $yellow;

              &:disabled {
                opacity: 0.5;
              }
            }
            &-fetch-site-data {
              margin-top: 2vw;
              padding: 0 10%;
              background: $yellow;
              height: 2.8vw;
              border-radius: 0;
            }
          }
          .help-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15vw;
            color: $black;
            border: 1px solid $lightGrey;
            margin: 0;
          }
        }
      }
    }
  }
  .row {
    margin: 0;
  }
  .col-xl-6,
  .col-lg-6,
  .col-md-6,
  .col-sm-6,
  .col-xs-6 {
    margin: 0;
    padding: 0;
  }
  .centralized-loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    margin-top: 18%;
    margin-left: -5%;
    .loader-img {
      width: 10rem;
      height: 10rem;
    }
    .loader-text {
      color: $grey;
    }
    .loader-description {
      color: #9c9c9c;
    }
  }
  .details-container {
    width: 100%;
    padding: 0 5%;
    margin: 0;
    h5 {
      font-weight: bolder;
    }
    .bolder {
      font-weight: bolder;
    }
    .lighter {
      font-weight: lighter;
      color: $grey;
    }
    .details-table {
      width: 100%;
      tr {
        td {
          padding: 2%;
        }
      }
    }
  }
}

.loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 25vh;
  flex-direction: column;
  align-items: center;
  .api-call-loader {
    width: 10vw;
    height: 10vw;
  }
  h4 {
    color: $grey;
  }
}

.data-grid-container {
  padding: 0% 2%;
  height: 82%;
  overflow-y: auto;

  .onboarding-form {
    .user-onboarding-dropdown {
      button {
        span {
          background: url(../../../assets/chevron-down.svg);
          background-repeat: no-repeat;
          background-position: left;
          background-size: 1.2vw;
          padding-right: 1.7vw;
          &::before {
            content: "";
          }
        }
      }
    }
    .k-multiselect {
      max-height: 6vw;
      overflow-y: auto;

      .k-input-inner {
        padding: 0.6vw;
      }
    }
    &-user-type {
      padding-bottom: 1%;
    }
    &-input-group {
      padding: 0 0 1%;
    }
  }
  .k-form {
    padding-bottom: 1%;
  }
  .k-form-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 13%;
  }
}

.k-panelbar {
  .k-panelbar-toggle.k-icon.k-i-arrow-chevron-down.k-panelbar-expand {
    &::before {
      content: "\e11e";
      font-size: 1vw;
      color: #ffffff;
    }
  }
  .k-panelbar-toggle.k-icon.k-i-arrow-chevron-up.k-panelbar-collapse {
    &::before {
      content: "\e121";
      font-size: 1vw;
      color: #ffffff;
    }
  }

  .k-child-animation-container {
    .k-panelbar-group .k-panel .k-group {
      li {
        .k-link {
          padding-left: 0 !important;
        }
      }
    }
    span.k-panelbar-toggle.k-icon.k-i-arrow-chevron-down.k-panelbar-expand {
      &::before {
        content: "\e015";
        font-size: 1vw;
        color: #050404;
      }
    }
    span.k-panelbar-toggle.k-icon.k-i-arrow-chevron-up.k-panelbar-collapse {
      &::before {
        content: "\e013";
        font-size: 1vw;
        color: #060505;
      }
    }
  }
}

.commentable-report-container {
  width: 100%;
  height: 78.5vh;
  background: white;
  overflow: hidden;
  .actions-header {
    padding: 0 1%;
    button {
      margin: 0;
      padding: 0.3vh 0.5vw;
      p {
        font-size: 0.7vw;
      }
      border-width: 0.1vw;
      .k-button-text {
        font-size: 0.7vw;
      }
    }
    .header-button-container {
      display: flex;
    }
    .toggle-container {
      display: flex;
      flex: 1 1;
      justify-content: space-between;
      margin-left: 2vw;
      margin-right: 0.5vw;
      .export-container {
        display: flex;
        width: -webkit-fill-available;
        .k-input {
          max-width: 10vw;
          font-size: 0.8vw;
        }
        .button-upload {
          margin: 0 0.7vw;
        }
        .date-picker {
          width: 100px;
        }
      }
    }
  }

  .power-bi-report {
    height: 72vh;
    .overlay-comments-form-container {
      background-color: white;
      width: 45% !important;
      left: 26vw;
      height: 80% !important;
      align-items: flex-end;
      bottom: -3%;
    }
    .comments-form-container {
      padding: 1%;
      border: 0.01vw solid #6e6e6e;
      width: 38%;
      margin: 0.7% 1%;
      height: 87.5%;
      overflow: scroll;
      .date-picker {
        margin-bottom: 0.5vw;
      }
      .loading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 2vw;
      }
      .no-comments {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 2vw;
        flex-direction: column;
        margin-top: 5%;
      }
      .submit-btn {
        background: $blue;
        color: $black;
        border-radius: 0;
        padding: 2%;
      }
      .close-btn {
        background: $red;
        color: $white;
        border-radius: 0;
        padding: 0.4vw;
      }
      .title {
        padding: 1% 2%;
        b {
          font-size: 1vw;
        }
        p {
          margin: 0;
        }
      }
      .close-btn-container {
        padding: 4%;
      }
      .submit-btn-container {
        display: flex;
        justify-content: flex-start;
        margin: 4% 0;
      }
      .field {
        margin: 5% 0;
        button {
          border-radius: 0;
          border: none;
        }
      }
      .previous-comments-table {
        margin-top: 2vw;
        width: 100%;
        height: 2vw;
        box-shadow: 0 0 5px #9f9f9f;
        font-size: 0.5vw;
        padding: 4%;
        thead {
          background: rgb(239, 239, 239);
        }
        .table-row {
          border-bottom: 0.01vw solid #eaeaea;
          td {
            width: 4vw;
            padding: 2%;
            font-size: 0.7vw;
          }
        }
      }
      strong {
        padding: 0.4vw;
      }
    }
    .report-container {
      height: 90%;
      padding: 0;
      .iframe-container {
        border: none;
        padding: 1% 0.5%;
        height: 73vh;
      }
    }
  }
}

.charts-parent-container {
  height: 76vh;
  .row {
    height: 100%;
    .charts-container {
      height: inherit;
      position: relative;
      bottom: 0;
    }
    .filter-container {
      height: 45%;
      padding: 2%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      button {
        border-radius: 0;
        outline: none;
        border: none;
      }
      .footer-btn {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .generate-chart-btn {
          background: $blue;
          color: $white;
        }
        .close-btn {
          background: $red;
          color: $white;
        }
      }
    }
  }
}

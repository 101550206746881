@import "../../../../../styles/variables.scss";
.new-issue-screen {
  position: absolute;
  align-self: center;
  top: 7.5%;
  width: 100%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10vw;
  z-index: 10;
  left: 17vw;
  .loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    left: 32vw;
    width: 32vw;
    .k-loading-mask,
    .k-loading-image,
    .k-loading-color {
      left: 0;
    }
    position: absolute;
    
    .loader-description {
      color: #868282;
    }
    .loading-text {
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
    .loading-image {
      position: absolute;
      top: 45%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
  }
  .title {
    display: flex;
    color: #404040;
    font-size: 0.7vw;
    font-weight: 700;
    text-transform: uppercase;
    flex: 1;
    justify-content: center;
  }
  .submit-btn-container {
    display: flex;
    justify-content: flex-end;
    margin: 2vh;
    .submit-btn {
      background: $darkBlue;
      color: $white;
      padding: 0.4vw 3vw;
      margin: 0 1vw;
    }
    .btn-cancel {
      background: $red;
      color: $white;
      padding: 0.4vw 2vw;
    }
  }
  .deliveryHeader {
    align-items: center;
    background: #efefef;
    display: flex;
    flex-direction: row;
    padding: 0.5vh;
    justify-content: center;
  }
  .issueContainer {
    margin-top: -3vh;
    background-color: white;
    .issueContent {
      padding: 1vh 2vw 0.5vh;
    }
    input.k-input {
      font-size: 0.8vw;
    }
    .k-input-md .k-input-inner,
    .k-picker-md .k-input-inner {
      font-size: 0.7vw;
    }
    .searchable-dropdown .k-input-value-text {
      font-size: 0.7vw;
    }
    .leftContainer {
      display: flex;
      flex: 0.5;
      padding: 0 5vw;
      flex-direction: column;
    }
    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 2vh;
    }
    .labelContainer {
      display: flex;
      flex: 0.4;
      height: max-content;
      font-size: 0.8vw;
    }
    .valueContainer {
      display: flex;
      flex: 0.6;
      width: 60%;
      height: max-content;
      font-size: 0.8vw;
    }
    .rightContainer {
      display: flex;
      flex: 0.5;
      padding: 0 5vw;
      flex-direction: column;
    }
  }
  .submitButtonContainer {
    display: flex;
    align-items: flex-end;
    .k-button {
      font-size: 0.7vw !important;
      margin: 0 0.2vw;
    }
    .toolbar-button {
      margin: 0 0.2vw;
      width: 4.5vw;
    }
  }
  .buttonsContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 3vh 4.5vw 1vh;
    .btn-primary {
      margin: 0 0.5vw;
      background: $yellow;
      span {
        font-size: 0.7vw;
        margin: 0 4%;
      }
    }
  }
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .new-issue-table {
    margin-bottom: 4%;
    td {
      max-width: 3vw;
    }
    thead {
      td {
        background-color: #eaeaea;
        font-weight: bold;
      }
    }
    input {
      width: 100%;
      height: 2vw;
    }
    .k-picker {
      height: 2vw;
    }
  }
}

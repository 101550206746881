@import "../../../../styles/variables.scss";

.pump-index-screen-container {
  .title {
    margin: 2% 0 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.4vw;
    display: block;
  }

  .warningIcon {
    display: flex;
    height: 1.5vh;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.3vw;
  }
  padding: 0 2% !important;
  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }
  
  .content-container {
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      padding: 0;
      .title {
        margin: 2% 1% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 0.7vw;
        font-weight: bold;
      }
    }
  }

  .pdfContainer {
    position: absolute;
    left: -10000000px;
    top: -10000000px;
    .k-grid td {
      font-size: 2vw;
      padding: 2vh 0.5vw !important;
    }
    .pdfHeader {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .data-grid-container {
    padding: 0;
    padding-top: 0;
    border: 0.1vw solid rgba(0, 0, 0, 0.2);
    margin: 0 1vw;
    display: flex;
    flex-direction: column;

    .k-grid-content {
      background-color: #ffffff;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .highlightedField {
      color: $red;
      font-size: 0.65vw !important;
    }
    .grid_footer {
      background: $paleGrey !important;
    }
    
    .HasTestSalesValue {
      color: $red;
    }
    .spaceContainer {
      border-left: 0.1vw solid rgba(0, 0, 0, 0.2);
      border-right: 0.1vw solid rgba(0, 0, 0, 0.2);
      padding: 1vw 1vw 0.5vw;
      .exportButton {
        background: $yellow;
        span {
          font-size: 0.7vw;
          margin: 0 2%;
        }
      }
    }
    .gridContainer {
      .k-grid th {
        padding: 0;
        .k-cell-inner > .k-link {
          justify-content: left;
        }
      }
    }
    .material-grid {
      .k-grid-content tr:last-child > td,
      .k-grid-content-locked tr:last-child > tr {
        background: #e5e5e5 !important;
        border: 0;
        border-top: 0.05vw solid rgba(0, 0, 0, 0.2);
        font-weight: bold;
      }
      .k-grid-content tr:last-child > td,
      .pump-index-screen-container
        .data-grid-container
        .material-grid
        .k-grid-content-locked
        tr:last-child
        > tr {
        position: sticky;
        bottom: 0;
        z-index: 10;
      }
    }
    .k-grid-content tr:last-child > td,
    .k-grid-content-locked tr:last-child > td {
      border: 0;
    }

    .k-grid-header.k-grid-draggable-header {
      -webkit-user-select: none;
      user-select: none;
      touch-action: none;
      background: #fcfcfc;
    }
    .k-master-row .k-grid-content-sticky {
      background-color: #f7f7f7 !important;
      border: 0;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2);
      white-space: pre-line;
      z-index: 100 !important;
    }
    .k-alt {
      background-color: #fff !important;
    }
    .noDataField {
      color: darkgrey;
    }
    .k-grid td {
      font-size: 0.65vw !important;
      padding: 0.8vh 0.5vw !important;
      overflow: visible;
      border: none;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
      background: white;

      input.k-input {
        height: inherit;
        background-color: white;
        border-radius: 0.3vw;
        width: -webkit-fill-available;
        border: 0.05vw solid rgba(0, 0, 0, 0.5);
        font-size: 0.8vw;
        padding: 0.5vh;
      }
    }
    .buttonsContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      border: 0;
      padding: 0 0 0.5vh 0;
      border-top: 0;
      border-bottom: 0;
      .languageContainer {
        padding: 0.2vw 0.1vw 0 0.4vw;
        margin: 1vh 1vw 0;
        border-radius: 0.3vw;
        .k-picker {
          border: 0.1vw solid rgba(0, 0, 0, 0.05);
          border-radius: 0.3vw;
        }
        .k-input-value-text {
          font-size: 0.75vw;
          white-space: inherit;
        }
      }

      .exportButton {
        margin: -0.5vw;
        background: $yellow;
        span {
          font-size: 0.7vw;
          margin: 0 2%;
        }
      }
    }

    .submitButtonContainer {
      display: flex;
      align-items: flex-end;
      margin: 1vw 1vw 0;
      .k-button {
        font-size: 0.7vw !important;
        margin: 0 0.2vw;
      }
      .toolbar-button {
        margin: 0 0.2vw;
        width: 4.5vw;
      }
      .switchContainer {
        display: flex;
        flex-direction: row;
        width: -webkit-fill-available;
        align-items: center;

        .switch-label {
          font-size: 0.7vw;
          font-weight: bold;
        }
      }
    }

    .k-grid th {
      padding: 0;
      border: 0 !important;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: normal;
      padding: 8px 12px !important;
    }

    .k-filter-row {
      display: none;
    }
    .k-table-md .k-table-th {
      padding: 3px 8px 8px 8px !important;
      margin: 0;
      vertical-align: middle !important;
      border-right: 0.1vw solid rgba(0, 0, 0, 0.08) !important;
      border-bottom: 0.1vw solid rgba(0, 0, 0, 0.08) !important;
      .k-cell-inner > .k-link {
        justify-content: center;
      }
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        margin-top: 0.6vh;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeaderLine {
        -webkit-line-clamp: 4;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }

  .empty-date {
    border-color: rgba(245, 5, 5, 0.857);
  }
  .date {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .header-container {
    display: flex;
    flex: 1;
    justify-content: center;
    border: 0;
    .filters-container {
      margin: 1vw 0 0;
      display: flex;
      flex-direction: row;
      .k-chip-label {
        white-space: normal;
        text-overflow: clip;
        display: contents;
      }
      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.3vh;
        font-weight: bold;
        font-size: $fontSize_0_65;
      }
      span {
        font-size: 0.65vw;
      }
      .k-input {
        padding-right: 1.2vw;
        font-size: 0.7vw;
        .k-clear-value {
          display: none;
        }
      }
      .filter {
        margin-left: 0.5vw;
        margin-right: 0.5vw;
        padding-right: 0.1vw;
        display: flex;
        flex-direction: column;
        min-height: 0;
        .k-picker {
          min-height: 0 !important;
        }
      }
      .filter-date {
        .k-daterange-picker,
        .k-daterangepicker {
          margin: 1vh 0 0;
        }
        .k-daterange-picker *,
        .k-daterangepicker * {
          max-width: 7.5vw;
        }
      }
      .filter-column {
        margin-left: 0.5vw;
        margin-right: 0.1vw;
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
      }
    }
    .filter-button {
      margin-left: 1vw;
      margin-right: 0;
      margin-top: 45px;
      .get-data-btn {
        padding: 0.2vw 1vw;
        background: $yellow;
        span {
          font-size: 0.7vw;
        }
      }
      .refresh-btn {
        margin: 0;
        span {
          font-size: 0.7vw;
        }
      }
    }
  }
}

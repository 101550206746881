@import "../../../../styles/variables.scss";

.k-animation-container {
  margin-right: 1.5vw !important;
}

.eom-summary-screen-container {
  padding: 0 2% !important;

  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }

  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }
  .content-container {
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0;

      .title {
        margin: 2% 1% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 0.7vw;
        font-weight: bold;
      }
    }
  }

  .data-grid-container {
    padding: 0;
    padding-top: 0;
    overflow-y: auto;
    border: 0.1vw solid rgba(0, 0, 0, 0.2);
    margin: 0 1vw;

    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .errorClassName {
      border: 0.1vw solid $red !important;
    }

    .editContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .editIndicator {
        background-color: $yellow;
        width: 0.5vw;
        height: 0.5vw;
        border-radius: 1vw;
      }
      .nonEditIndicator {
        width: 0.5vw;
        height: 0.5vw;
        border-radius: 1vw;
      }
    }

    .userIconContainer {
      display: flex;
      margin-left: 0.1vw;
      height: 0.5vh;
      align-items: center;
      width: 0.5vh;
    }
    .userIcon {
      display: flex;
      height: 1.5vh;
    }
    .nonEditableCell {
      padding: 1.7vh 0.6vw !important;
    }
    .editableCell {
      padding: 0.9vh 0.5vh !important;
      .k-form-hint,
      .k-form-error {
        font-size: 0.5vw;
      }
    }
    .k-button-solid-base {
      margin: 0.2vh;
      padding: 0.3vh 0.3vw;
    }
    .retry-container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.65vw;
      .retry-icon {
        margin-left: 0.2vw;
        margin-bottom: 0.2vh;
        width: 1vw;
        height: 1vw;
      }
    }
    .k-grid td {
      font-size: 0.6vw;
      padding: 0.5vh 0.3vw;
      border: none;
      background: white;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
      input.k-input {
        height: inherit;
        padding: 0.5vh;
        margin: 0 5px;
        background-color: white;
        border-radius: 0.3vw;
        width: -webkit-fill-available;
        border: 0.05vw solid rgba(0, 0, 0, 0.5);
        font-size: 0.58vw;
      }
      .lastValue {
        font-size: $fontSize_0_65;
      }
    }
    .ship-to-link-btn:focus {
      box-shadow: none;
    }
    .buttonsContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      border: 0;
      padding: 0;
      border-top: 0;
      border-bottom: 0;
      margin-top: 1vh;
      margin-right: 1vw;
      margin-bottom: 0.3vh;
      .filter-button {
        margin-left: 1vw;
        padding-bottom: 0.5vh;
        margin-right: 0;
        .get-data-btn {
          padding: 0.2vw 1vw;
          background: $yellow;
          span {
            font-size: 0.7vw;
          }
        }
      }
      .exportButton {
        margin: 0.2vh 1.5vw 0;
        background: $yellow;
        align-self: end;
        padding: 0.3vh 0.8vw;
        span {
          font-size: 0.7vw;
          margin: 0 2%;
        }
      }
    }

    .submitButtonContainer {
      display: flex;
      align-items: flex-end;
      margin: 0.5vh 0.5vw;
      .k-button {
        font-size: 0.7vw !important;
        margin: 0 0.2vw;
      }
      .toolbar-button {
        margin: 0 0.2vw;
        width: 4.5vw;
        padding: 0.3vh;
      }
    }

    .k-grid th {
      border: 0 !important;
      padding: 0;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: anywhere;
      padding: 8px 12px !important;
    }
    .k-grid .k-grid-header .k-table-th {
      position: relative;
      vertical-align: middle;
      cursor: default;
    }
    .k-grid-md .k-table-th > .k-cell-inner {
      margin-block: 0;
      margin-inline: calc(var(--kendo-spacing-2, 0.75rem) * -1);
    }
    .k-table-md .k-table-th {
      padding: 0 0.5vh 1vh;
      margin: 0;
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        white-space: normal;
        margin-top: 0.6vh;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }

  .empty-date {
    border-color: rgba(245, 5, 5, 0.857);
  }
  .date {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .header-container {
    display: flex;
    flex: 1;
    justify-content: center;
    border: 0;
    .filters-container {
      margin: 1vh 0.5vw 1vh 0.5vw;
      display: flex;
      flex: 1;
      flex-direction: row;
      .k-chip-md .k-chip-action {
        padding: 0;
      }
      .filter {
        margin-left: 0.5vw;
        padding-right: 0.1vw;
        margin-right: 0.3vw !important;
        display: flex;
        flex-direction: column;
        min-height: 0;
        .k-picker {
          min-height: 0 !important;
          margin-right: 0.5vw !important;
        }
        .k-chip-solid-base {
          height: 100%;
        }
      }
      .k-chip-label {
        white-space: normal;
        text-overflow: clip;
        display: contents;
      }
      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.3vh;
        font-weight: bold;
        font-size: $fontSize_0_65;
      }
      span {
        font-size: 0.65vw;
      }
      .k-input {
        padding-right: 0;
        font-size: 0.7vw;
        height: 100%;
        .k-clear-value {
          display: none;
        }
      }
      .filter-date {
        padding: 0;
        margin-right: 0;
        span {
          min-height: 3.1vh;
        }
        .k-daterangepicker {
          margin: 0;
          gap: 8px;
          height: 100%;

          .dateContainer {
            height: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
          }
        }
        .k-daterangepicker .k-dateinput {
          max-width: 8.5vw;
          margin-bottom: 0.3vh;
          height: 100%;
          max-height: 28px;
        }
      }
      .filter-column {
        margin-left: 0.5vw;
        margin-right: 0.1vw;
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
      }
    }
  }
  .stock-log-content {
    background-color: white;
  }
  pre {
    background-color: white;
    border: none;
  }
  .api-message-container {
    display: flex;
    flex-direction: column;
    padding: 1vw 0 1vw 1vw;
    
    .card-container {
      display: flex;
      flex-direction: row;
      margin-top: 1vh;
      .card {
        display: flex;
        flex: 0.5;
        flex-direction: column;
        border: 0.07vw solid rgba(0, 0, 0, 0.4);
        border-radius: 0.5vw;
        margin-right: 1vw;
        overflow: hidden;
        height: fit-content;
        .card-header {
          background-color: $paleGrey;
          overflow: hidden;
          flex-shrink: 0;
          padding: 0.5vh 1.1vw;
          border-top-left-radius: 0.5vw;
          border-top-right-radius: 0.5vw;
          font-size: 0.8vw;
          font-weight: bold;
          border-bottom: 0.07vw solid rgba(0, 0, 0, 0.1);
        }
        .card-content {
          display: flex;
          max-height: 60vh;
          padding: 0;
          padding-top: 1vh;
        }
      }
    }
    .child-card {
      max-height: 56vh;
    } 
    .json-container {
      padding-top: 2vh;
      .log-content {
        display: flex;
        flex: 1;
        padding-left: 0.2vw;
      }
    }

    pre {
      padding: 0 0 0 1vw;
      display: flex;
      flex: 1;
    }
    span {
      margin: 0;
    }
    .json-key {
      font-size: 0.8vw;
      font-weight: bold;
    }
    .json-response {
      font-size: 0.7vw;
    }
  }
}

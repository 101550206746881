@import "../../../../styles/variables.scss";
.site-info-container {
  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }
  .pumpGridContainer {
    width: min-content;
    .k-grid-header-wrap > table {
      height: 100%;
      width: 100% !important;
      .k-table-th {
        padding: 0 5px 5px 5px;
        margin: 0;
      }
    }
  }
  .container-fluid {
    :hover {
      background-color: $white;
    }
  }
  .header-container {
    display: flex;
    flex-direction: column;
  }

  .header-box-container {
    display: flex;
    flex: 1;
    margin: 1vh 5vh 2vh;
    .boxContainer {
      display: flex;
      flex-direction: row;
      margin-right: 1vw;
      box-shadow: 1px 1px 5px 3px #e1e1e1;
      width: 15vw;
      align-items: center;
      .box-title {
        white-space: normal;
        padding: 0.3vw 0.5vw;
      }
      .box-count {
        white-space: normal;
        padding: 0.3vw 0.5vw;
        flex: 1;
        text-align: end;
      }
      span {
        font-size: 0.8vw;
        margin: 0;
      }
      .highlighter {
        background-color: $darkBlue;
        width: 10px;
        align-self: normal;
      }
    }
  }
  .data-grid-container {
    height: 95%;
    overflow-y: auto;

    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }
    .k-grid td {
      border: none;
      background: white;
      padding: 0.7vh;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
    }

    .siteList-grid-container {
      overflow-y: visible;
    }

    .k-table-th {
      padding-left: 0.5vw;
      margin: 0;
      border: 0;
    }

    .k-grid th:first-child {
      border: 0;
      display: flex;
      flex: 1;
      align-items: flex-end;
    }

    .k-customHeader {
      font-weight: bold;
      font-size: 0.65vw;
      margin-top: 0.6vh;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-family: Arial, sans-serif;
    }
    .k-customHeader:hover {
      cursor: pointer !important;
    }

    .numericInput {
      .k-input {
        width: 5vw !important;
        height: 3vh;
        padding: 0 0 0 0.2vw;
      }
    }
    .k-table-td {
      font-size: 0.65vw;
      overflow-wrap: anywhere;
      :hover {
        background-color: $white;
      }
    }
    .k-grid tr .k-grid .k-detail-row .k-detail-cell {
      :hover {
        background-color: $white;
      }
    }
    .k-column-title {
      font-size: 0.7vw;
    }
    .k-button *,
    .k-button *::before,
    .k-button *::after {
      font-size: 0.7vw;
    }
  }
  .k-input-md .k-input-inner {
    font-size: 0.7vw !important;
  }
  .popupContainer {
    position: absolute;
    align-self: center;
    top: 8%;
    width: 90%;
    left: 17%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10vw;
    z-index: 10;

    .leftContainer {
      flex: 1;
      margin-right: 1vw;
      flex-direction: column;
    }
    .rightContainer {
      flex: 1 1;
      margin-right: 1vw;
    }

    .loaderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 14%;
      top: 15%;
      width: 60%;
      height: 67%;
      z-index: 100;
    }
    .tank-footer-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .info-label {
        color: $red;
        padding: 1vw;
        font-size: 0.8vw;
      }
    }
    .submit-btn-container {
      display: flex;
      justify-content: flex-end;
      margin: 1vw;
      .submit-btn {
        background: $darkGreen;
        color: $white;
        padding: 0.4vw 1.5vw;
      }
      .close-btn {
        background: $red;
        color: $white;
        margin: 0 1vw;
        padding: 0.4vw 1.5vw;
      }
    }
    .deliveryForm {
      display: flex;
      background-color: white;
      flex-direction: column;
      overflow: auto;
      width: auto;
      height: auto;
      .errorIcon {
        display: flex;
        height: 1.8vh;
        position: relative;
        top: 0.8vh;
      }
      .remarksContainer {
        margin-top: 1vw;
        margin-left: 1vw;
        min-width: 15vw;
        max-width: 24.2vw;
        .remarksTextArea {
          font-size: 0.8vw;
        }
      }
      .openingHourTitle {
        font-weight: bold;
        padding: 1vh 0;
        font-size: 0.8vw;
      }
      .openingHourNA {
        padding-left: 2vw;
      }
      .openingHourContainer {
        display: flex;
        flex-direction: column;
        max-width: 23vw;
        border: 0.08vw dashed rgba(0, 0, 0, 0.3);
        margin-left: 2vw;
        padding: 1vh 0;
      }
      .openingHrsRow {
        display: flex;
        flex-direction: row;
        padding: 0.7vh 0;
        font-size: 0.7vw;
        align-items: center;
        .dateLbl {
          width: 5vw;
          color: #4e4e4e;
          font-weight: 600;
          font-size: 0.7vw;
        }
        .dateVal {
          display: flex;
          flex-direction: row;
          margin: 0;
          font-size: 0.7vw;

          .k-input-inner {
            font-size: 0.7vw;
            padding: 0.2vw;
          }
          span {
            margin: 0;
          }
        }
      }
      .timePicker {
        margin-right: 0.5vw !important;
        margin-left: 0.5vw !important;
        height: 3vh;
        width: 7vw;
      }
      .deliveryHeader {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        height: 3vw;
        background: $paleGrey;
        align-items: center;

        .title {
          padding: 1vw;
          flex: 1;
          font-size: 0.9vw;
        }
      }

      .deliveryContent {
        display: flex;
        padding: 1vw 1vw;
        max-width: 54.5vw;
        flex-direction: row;
        .deliveryRow {
          display: flex;
          flex: 1;
          flex-direction: row;
          padding: 0.7vh 0;

          .errorClassName {
            border: 0.1vw solid $red !important;
          }
          .deliveryLbl {
            width: 11.5vw;
            color: #4e4e4e;
            font-weight: 600;
          }
          .deliveryVal {
            display: flex;
            margin: 0;
            width: 14vw;
            .k-input-value-text {
              white-space: normal;
            }
          }
          .dateLbl {
            width: 11.5vw;
            color: #4e4e4e;
            font-weight: 600;
            padding-right: 1vw;
          }
          .dateVal {
            width: 12vw;
            display: flex;
            flex-direction: row;
            margin: 0;

            .k-input-inner {
              font-size: 0.8vw;
            }
            span {
              margin: 0;
            }
          }
          .tank-label {
            display: flex;
            flex-direction: row;
            margin: 0;
            width: 7.3vw;
            color: #4e4e4e;
            font-weight: 600;

            .k-input-inner {
              font-size: 0.8vw;
            }
            span {
              margin: 0;
            }
          }
          .tank-text-input {
            display: flex;
            margin: 0;
            max-width: 10vw;
            border: 0.1vw solid rgba(0, 0, 0, 0.24);
            input.k-input {
              width: 15vw !important;
              font-size: 0.7vw;
              border: 0;
            }
          }
          .tank-val {
            display: flex;
            flex-direction: row;
            margin: 0;

            .k-input-inner {
              font-size: 0.8vw;
            }
            span {
              margin: 0;
            }
          }
          .inputLbl {
            width: 11.5vw;
            min-width: 7vw;
            color: #4e4e4e;
            font-weight: 600;
          }
          span {
            font-size: 0.7vw;
            margin: 0;
          }
          .inputVal {
            display: flex;
            margin: 0;
            width: 13vw;
            border: 0.1vw solid rgba(0, 0, 0, 0.24);
            input.k-input {
              width: 15vw !important;
              font-size: 0.7vw;
              border: 0;
            }
          }
        }
      }
    }
  }
  .editIcon {
    display: flex;
    height: 1.7vh;
  }
  .get-data-btn {
    margin-top: 15%;
    min-width: 100%;
    padding: 4%;
    background: $yellow;
  }
  .k-table-th {
    padding: 0 1vh 0.5vw 0;
    margin: 0;
  }
  .cancel-btn {
    padding: 0.3vh 0.5vw;
    margin-right: 2vw;
  }
  .edit-btn {
    padding: 0;
    margin-right: 2vw;
    background: transparent;
  }
  .add_tg-btn {
    padding: 0.3vh 0.5vw;
    color: white;
    margin-right: 1vw;
    background-color: #198754;
  }
 
  .filters-container {
    flex-direction: row;
    margin-bottom: -1.8vw;
    display: flex;
    align-items: center;
    .statusFilter {
      width: 6.5vw;
      margin-left: 2.5vw;
    }
    .startDateFilter {
      width: 7.5vw;
      margin-left: 0.7vw;
      margin-right: 10px;
    }
    .endDateFilter {
      width: 7.5vw;
      margin-left: 1px;
    }
    .getData {
      margin-left: 1px;
    }
  }
  .cityContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 12vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }
  .openingHoursContainer {
    .k-table-th {
      padding: 0 15px 5px 0px;
      margin: 0;
    }
    .remarksContainer {
      margin-top: 1vw;
      .remarksButtonContainer {
        display: flex;
        justify-content: flex-end;
        .remarksButton {
          margin-top: 0.3vw;
          padding: 0.12vw;
          background: $yellow;
        }
      }
      .remarksTextArea {
        font-family: $primary;
        font-size: 12px;
      }
    }
  }
  .details-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10vh;
    margin-left: -5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: lightgrey;
    padding: 2vw;
    width: 15vw;
  }
  .remarks-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10vh;
    margin-left: -5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .loader-description {
      color: #868282;
      width: 100%;
    }
  }
  .exportContainer {
    display: flex;
    padding-bottom: 0.5vh;
    align-items: flex-end;
    justify-content: flex-end;
    .exportButton {
      margin-right: 1vw;
      background: $yellow;
    }
    .refreshButton {
      margin-right: -1.3vw;
    }
  }
}

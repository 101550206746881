@import "../../../../styles/variables.scss";

.k-animation-container {
  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }
  .k-list-filter {
    padding: 0.2vw;
    .k-input:focus-within {
      border-color: $blue;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
    }
  }
  .k-treeview {
    padding-bottom: 1vh;
    max-height: 30vh;
    flex: 1;
    span {
      margin: 0;
      font-size: $fontSize_0_65;
    }
    .k-treeview-item {
      padding: 0 0 0 1.2vw;
      width: fit-content;
    }
  }
  .k-popup {
    border: none;
    span {
      font-size: 0.65vw;
    }
    width: -webkit-fill-available !important;
  }
  .k-input {
    font-size: 0.7vw;
  }
  .k-svg-icon > svg {
    height: 0.8vw;
  }
  .k-no-data,
  .k-nodata {
    background: white;
  }
}

.message-tracker-screen-container {
  padding: 0 1% !important;
  .k-expander {
    margin-bottom: 1vh;
    border-color: $lightBlue;
  }
  .k-expander.k-focus {
    box-shadow: none;
  }
  .k-expander-content {
    padding: 0.7vh 1.2vw;
  }
  .k-expander-title {
    color: $black;
    font-weight: bold;
    font-family: $primary;
    text-transform: capitalize;
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    .count-grid-container {
      margin-bottom: 2vw;
    }
    ::-webkit-scrollbar {
      width: 0.3vw;
      display: block;
    }

    ::-webkit-scrollbar-thumb {
      background: $lightGrey;
      border-radius: 0.5vw;
    }
  }

  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }

  .data-grid-container {
    box-shadow: none;
    padding: 0.5% 0 0 0;
    display: flex;
    flex-direction: column;
    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .k-grid {
      border-width: 0.06vw;
    }

    .k-grid td {
      font-size: 0.65vw;
      padding: 1vh 0;
      background-color: white;
      border: none;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
    }

    .refresh-btn {
      margin: 0 1vw;
      span {
        font-size: 0.7vw;
      }
    }

    .message-container {
      padding: 1vw 0.1vw;
    }
    .k-grid th {
      border: 0 !important;
      padding: 8px;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: anywhere;
    }
    .k-table-md .k-table-th {
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        margin-top: 0.6vh;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }
  .header-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    .filters-container {
      margin-right: 8.5vw;
      margin-left: 0;
      display: flex;
      flex: 1;
      justify-content: left;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 2vh;
      .filter {
        display: flex;
        flex-direction: column;
        margin-left: 0.5vw;
        margin-right: 0;
        .k-chip-solid-base {
          height: 100%;
        }
        .k-picker {
          width: 6vw !important;
          height: 3vh;
          border-radius: 0.2vw;
        }
      }
      .filter-date {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 0 0.5vw;
        width: 17vw;
        .k-input {
          padding-right: 0;
          height: auto;
          width: 8vw;
          font-size: 0.7vw;
        }
      }
    }
    .filter-button {
      margin-left: 0;
      margin-right: 0;
      .get-data-btn {
        padding: 0.2vw 0.5vw;
        background: $yellow;
        span {
          font-size: 0.7vw;
        }
      }
    }
  }
}

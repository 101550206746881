@import "../../../styles/variables.scss";
.menu-container {
  display: block;
  padding-top: 8vh;
  width: 100%;
  ul{
    overflow-y: scroll;
    max-height: 79.5vh;
    scrollbar-width:thin;
  }
}

.reportMenuContainer {
  .accordian-container {
    background: $menuChild !important;
    .search-container .input-field {
      background-color: $menuChild !important;
      color: white;
    }
    .input-field::placeholder {
      color: white;
    }
    .shell-menu-label-text {
      white-space: normal !important;
      font-size: 0.8vw !important;
      color: white;
    }
  }
  .menu-cwr {
    background-color: #ddf2f7;
  }
  .menu-item {
    background-color: $menuChild;
    color: white;
    font-weight: normal;
  }
  .sub-menu {
    background-color: $menuSub;
    font-weight: normal;
  }
  .parent-menu {
    background-color: $menuDark;
    font-weight: bold;
  }
  .shell-menu-submenu .shell-menu-submenu-expand-icon {
    background-color: white;
  }
}

.k-dialog {
  min-width: 10vw;
  font-weight: 400;
}
.k-window-title {
  justify-content: center;
}
.alert-popup-container {
  display: flex;
  .alert-message {
    display: flex;
    font-size: 0.8vw;
    font-weight: 400;
    padding-bottom: 5vh;
    justify-content: center;
    
  }
}
@import "../../../../styles/variables.scss";

.gain-loss-screen-container {
  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }
  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }
  .content-container {
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0;

      .title {
        margin: 2% 1% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 0.7vw;
        font-weight: bold;
      }
    }
  }

  .header-box-container {
    display: flex;
    flex: 1 1;
    margin: 0 0 1.5vh 0;
    justify-content: center;
  }
  .footer-box-container {
    display: flex;
    flex: 1 1;
    margin: 1vh 0 1vh 0;
    justify-content: center;
  }

  .errorIcon {
    display: flex;
    height: 1.8vh;
  }
  .boxContainer {
    display: flex;
    flex-direction: row;
    margin-right: 1vw;
    box-shadow: 1px 1px 5px 3px #e1e1e1;
    min-width: 8vw;
    align-items: center;
    .box-title {
      white-space: normal;
      padding: 0.3vw 0.5vw;
    }
    .box-count {
      white-space: normal;
      padding: 0.3vw 0.5vw;
      flex: 1;
      text-align: end;
      font-weight: bold;
    }
    span {
      font-size: 0.7vw;
      margin: 0;
    }
    .highlighter {
      background-color: $darkBlue;
      width: 0.3vw;
      align-self: normal;
    }
  }
  .data-grid-container {
    padding: 0;
    padding-top: 0;
    border: 0.1vw solid rgba(0, 0, 0, 0.2);
    margin: 0 1vw;
    display: flex;
    flex-direction: column;

    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .k-grid th:first-child {
      border: 0;
      cursor: pointer;
      padding: 0;
      display: flex;
    }

    .data-grid-content {
      margin-bottom: 0;
      span {
        margin: 0;
      }
    }
    .k-grid td {
      padding: 0.4vw 0.5vw 0.4vw 0.8vw !important;
      font-size: $fontSize_0_65;
      background-color: white;
      border: none;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
      .k-button * {
        box-sizing: unset;
        font-size: $fontSize_0_65 !important;
        text-decoration: underline;
        color: $darkBlue;
        border: none;
      }
    }

    .k-grid th {
      border: 0 !important;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: anywhere;
      padding: 8px 12px !important;
    }
    .k-table-md .k-table-th {
      padding: 3px 8px 8px 8px !important;
      margin: 0;
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        margin-top: 0.6vh;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
        width: max-content;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-grid-pager {
      border-top: 0.1vw solid rgba(0, 0, 0, 0.2);
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }

  .headerContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0.3vw;
    .refresh-btn {
      margin: 0.3vw 0.3vw;
      span {
        font-size: 0.7vw;
      }
    }
  }

  .gridToolbar {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    border: 0;
    .empty-date {
      border-color: rgba(245, 5, 5, 0.857);
    }
    .date {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .header-container {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: flex-end;
      margin: 1vw 0.2vw 0.7vw 0.2vw;
      .filters-container {
        margin-right: 0;
        display: flex;
        flex-direction: row;

        .k-chip-md {
          padding: 0.3vh 0;
          font-size: 0.7vw;
          line-height: 1;
          margin: 0;
        }
        .k-chip-md .k-chip-action {
          padding: 0;
        }
        .k-input-md .k-input-values {
          width: 100%;
          padding: 0.1vw;
          gap: 2px;
          cursor: text;
        }
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 0.3vh;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
        .k-chip-label {
          white-space: normal;
          flex: 1 1 auto;
          display: flex;
        }
        span {
          font-size: 0.65vw;
          margin-right: 0.3vw !important;
          padding-right: 0;
          padding-left: 0;
        }
        .k-chip-actions {
          width: 0.6vw;
          .k-svg-icon > svg {
            width: 0.7vw;
          }
        }
        .k-input {
          padding-right: 1.2vw;
          font-size: 0.7vw;
          .k-clear-value {
            display: none;
          }
        }
        .filter {
          margin-left: 0;
        }
        .filter-date {
          .k-daterange-picker *,
          .k-daterangepicker * {
            max-width: 7.5vw;
          }
          span {
            min-height: 3.1vh;
          }
        }
        .filter-column {
          margin-left: 0.5vw;
          margin-right: 0.1vw;
          label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: $fontSize_0_65;
          }
        }
      }
      .filter-button {
        margin-left: 0.8vw;
        padding-bottom: 0vh;
        align-self: end;
        margin-bottom: 0.2vh;
        .get-data-btn {
          padding: 0.2vw 1vw;
          background: $yellow;
          margin: 0;
          span {
            font-size: 0.7vw;
          }
        }
      }
    }
  }
}

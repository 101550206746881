@import "../../../../styles/variables.scss";

.message-tracker-screen-container {
  padding: 0 1% !important;
  .grid-container {
    display: flex;
    flex-direction: column;
    .count-grid-container {
      margin-bottom: 2vw;
    }
    ::-webkit-scrollbar {
      width: 0.3vw;
      display: block;
    }

    ::-webkit-scrollbar-thumb {
      background: $lightGrey;
      border-radius: 0.5vw;
    }
  }
  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }

  .data-grid-container {
    box-shadow: none;
    padding: 0.5% 0 0 0;
    display: flex;
    flex-direction: column;
    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .k-grid {
      border-width: 0.06vw;
    }

    .k-grid td {
      font-size: 0.65vw;
      padding: 1vh 1vw;
      background-color: white;
      border: none;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
    }

    .refresh-btn {
      margin: 0 1vw;
      span {
        font-size: 0.7vw;
      }
    }

    .message-container {
      padding: 1vw 0.1vw;
    }
    .k-grid th {
      border: 0 !important;
      padding: 8px;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: anywhere;
    }
    .k-table-md .k-table-th {
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        margin-top: 0.6vh;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }
  .header-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    .filters-container {
      margin-right: 8.5vw;
      margin-left: 0;
      display: flex;
      flex: 1;
      justify-content: left;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 2vh;
      .filter-button {
        margin-left: 0;
        margin-right: 0;
        .get-data-btn {
          padding: 0.2vw 0.5vw;
          background: $yellow;
          span {
            font-size: 0.7vw;
          }
        }
      }
    }
  }
}

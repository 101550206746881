@import "../../../../styles/variables.scss";

.user-popupContainer {
  position: absolute;
  align-self: center;
  top: 8%;
  width: 90%;
  left: 17%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  padding-right: 10vw;
  z-index: 10;
  display: flex;

  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 35%;
    top: 30%;
    width: 20%;
    height: 35%;
    z-index: 100;
  }

  .user-add-content-container {
    display: flex;
    flex-direction: column;
    .rowContainer {
      display: flex;
      flex: 1;
      flex-direction: row;
      padding: 0.7vh 1vw;
      margin-right: 2.5vw;
    }

    .input-error {
      border-color: rgba(213, 25, 35, 0.5) !important;
    }

    .radio-row {
      align-items: center;
    }
    .label {
      display: flex;
      min-width: 8vw;
      max-width: 12vw;
      color: #4e4e4e;
      font-weight: 600;
      font-size: 0.75vw;
    }
    .value {
      display: flex;
      flex-direction: column;
      margin: 0;

      .check-box input[type=checkbox] {
        border: 0.1vw solid rgba(0, 0, 0, 0.24);
        width: 1.2vw;
        height: 1.2vw;
      }

      .k-input {
        max-height: 10vh;
        overflow-y: scroll;
      }
      .site-access-note {
        padding: 2% 0;
        margin: 0;
        .note {
          font-size: 0.8vw;
          font-weight: 600;
        }
        .info {
          font-size: 0.75vw;
        }
      }

      .error-message {
        font-size: 0.7vw;
        width: 15vw;
        color: $red;
        font-weight: 600;
      }
      .drop-down-field {
        width: 15vw;
      }
      input.k-input {
        width: 15vw;
        font-size: 0.7vw;
        border: 0.1vw solid rgba(0, 0, 0, 0.24);
      }
      .k-radio-list-item .k-radio-label {
        margin: 0;
        font-size: 0.8vw;
        min-width: fit-content;
      }
    }
  }
  .submit-btn-container {
    display: flex;
    justify-content: flex-end;
    margin: 1vw;
    margin-right: 2.5vw;
    .submit-btn {
      background: $darkGreen;
      color: $white;
      padding: 0.3vw 1vw;
    }
    .close-btn {
      background: $yellow;
      margin: 0 1vw;
      padding: 0.3vw 1vw;
    }
  }
  .deliveryForm {
    display: flex;
    background-color: white;
    flex-direction: column;
    overflow: auto;
    height: auto;
    width: auto;
    
    .errorIcon {
      display: flex;
      height: 1.8vh;
      position: relative;
      top: 0.8vh;
    }
    .deliveryHeader {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: 3vw;
      background: $paleGrey;
      align-items: center;

      .title {
        padding: 1vw;
        padding-left: 3vw;
        flex: 1;
        font-size: 0.9vw;
        justify-content: center;
        display: flex;
      }
      .close-btn-container {
        padding: 1vh;
        .close-btn {
          background: $red;
          color: $white;
        }
      }
    }
  }
}

@import "../../../../../styles/variables.scss";


.k-window {
  height: fit-content !important;
  max-height: 50%;
  border: 0.1vw solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.issue-update-screen {
  .searchable-dropdown .k-input-value-text {
    overflow: visible !important;
    text-overflow: ellipsis !important;
    text-wrap: wrap;
    font-size: 0.7vw;
  }
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 64vh;

    .emptyItem {
      height: 10px;
    }
  }
  .ContentWindow {
    display: flex;
    min-height: 100px;
  }
  .k-expander-content {
    padding: 1vw;
    padding-bottom: 0;
  }
  .k-expander {
    width: 76.5vw;
    border-color: white;

    .k-expander-header {
      background-color: #efefef;
    }
  }

  .contentContainer {
    display: flex;
    flex: 1;
    .leftContainer {
      flex: 1;
      margin-right: 1vw;
      flex-direction: column;
      width: 30%;
    }
    .middleContainer {
      flex: 1 1;
      width: 30%;
      margin-right: 1vw;
    }
    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 2vh;
      flex: 1;
    }
    .rightContainer {
      flex: 1 1;
      width: 30%;
    }
    .labelContainer {
      display: flex;
      flex: 0.35 1;
      height: max-content;
      font-size: 0.7vw;
      font-weight: bold;
    }
    .view-history-link {
      cursor: pointer;
      color: $blue;
      font-weight: bold;
    }
    .valueContainer {
      display: flex;
      flex: 0.6;
      width: 60%;
      height: max-content;
      font-size: 0.7vw;
      padding-left: 1vw;
      .textarea {
        display: flex;
        flex: 1;
        padding: 0.2vw;
      }
    }
  }
  .k-expander-title {
    color: goldenrod;
    font-weight: bold;
  }
  .expanded {
    border-color: #ffd500;
  }
  .k-expander.k-focus {
    box-shadow: inset 0 0 0 1px #ffd500;
  }
  .header-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .left {
      margin-left: 2.5%;
      margin-top: 2%;
      margin-bottom: 2%;
      width: 50rem;
      h4 {
        font-weight: bolder;
        margin-left: 1%;
        margin-bottom: 1%;
      }
      p {
        margin-left: 1%;
        color: $grey;
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      margin-right: 2.3vw;
      margin-bottom: 1vh;
      .submit-btn {
        background: $darkBlue;
        color: $white;
        padding: 0 2vw;
        margin: 0 1vw;
      }
      .btn-cancel {
        background: $red;
        color: $white;
        padding: 0.2vw 2vw;
      }
    }
  }
  .update-form-container {
    height: calc(62vh);
    overflow: scroll;
    .update-form {
      margin: 0 3%;
      h6 {
        margin: 1% 0;
        font-weight: bold;
      }
      p {
        margin: 1% 0;
      }
      .update-table {
        width: 100%;
        .update-table-header {
          background-color: #eaeaea;
          tr {
            td {
              font-weight: bold;
              padding: 0.5%;
            }
          }
        }
        .update-table-body {
          .textarea {
            resize: none;
            width: 100%;
            overflow: hidden;
            height: "auto";
            padding: 2px 2px;
            border: 0.1vw solid rgba(0, 0, 0, 0.24);
          }
          .textarea:focus {
            outline: none !important;
            border: 0.1vw solid rgba(0, 0, 0, 0.24);
          }
          thead {
            td {
              background-color: #eaeaea;
              font-weight: bold;
            }
          }
          input {
            width: 100%;
            height: 2vw;
          }
          .k-picker {
            height: 2vw;
          }
          td {
            padding: 1%;
            .view-history-link {
              cursor: pointer;
              color: $blue;
              font-weight: bold;
            }
          }
          tr {
            vertical-align: top;
          }
        }
      }
    }
  }
}

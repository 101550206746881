// --------------------------------------------------------------------
// DESCRIPTION
// This file defines the variables being used throught the website.
// --------------------------------------------------------------------

@font-face {
  font-family: Arial;
  src: url(../../assets/ariel.ttf) format("ttf");
}

// COLORS
$yellow: #ffc107;
$red: #bb2d3b;
$blue: #66c5d2;
$darkBlue: #0092a5;
$lightBlue: #b8e2ea;
$lightBlueShadeDark: #66c5d2;
$white: #fff;
$black: #595959;
$grey: #6e6e6e;
$mediumGrey: #7f7f7f;
$lightGrey: #c4c4c4;
$green: #96C896;
$darkGreen: #198754;
$paleGrey: #efefef;
$menuDark: #007484;
$menuSub: #1296a8;
$menuChild: #13acc1;

// FONT NAMES
$primary: "Arial";
$gridFont: "Helvetica Neue";

// FONT SIZES
$h1: 2vw;
$h2: 1.8vw;
$h3: 1.6vw;
$h4: 1.4vw;
$h5: 1.2vw;
$h6: 1vw;
$p: 0.8vw;
$span: 0.8vw;
$fontSize_0_50: 0.50vw;
$fontSize_0_55: 0.55vw;
$fontSize_0_60: 0.6vw;
$fontSize_0_65: 0.65vw;
$fontSize_0_68: 0.65vw;
$fontSize_0_70: 0.7vw;
$fontSize_0_75: 0.75vw;
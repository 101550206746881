@import "../../styles/variables.scss";

.login-page {
  height: 100vh;
  .login-container {
    color: $white;
    position: absolute;
    left: 10%;
    top: 15%;
    margin: 0;
    .brand-container {
      width: 20vw;
      height: 25vw;
      .brand-container-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10vw;
        h1 {
          font-weight: bolder;
        }
        .error-message {
          text-align: center;
          color: $red;
          text-shadow: 0px 0px 5px $red;
        }
        img {
          width: 4vw;
          margin-bottom: 2%;
        }
      }
      .login-options-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        .login-option {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: inherit;
          padding: 5% 0;
          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            div {
              padding: 0;
            }
            input {
              margin: 0;
              margin-top: 2%;
            }
            .text {
              font-size: 1.2vw;
            }
          }
        }

        .login-form-container {
          width: 100%;
          .field {
            color: $black;
            border-radius: 0.5vw;
            margin: 0;
            padding: 2%;
            width: inherit;
            height: 2vw;
            font-size: 1vw;
            margin-bottom: 2%;
            border-width: 0.2vw;
            border: 0.2vw solid $black;
          }
          .login-btn {
            width: inherit;
            height: 2vw;
            font-size: 1vw;
            padding: 1%;
            margin-bottom: 2%;
          }
        }
      }
    }
  }
  footer {
    color: $white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 5% 0;
    h3 {
      margin-bottom: 1vw;
      .seperator {
        color: $white;
        margin: 0;
        font-size: 2vw;
      }
      a {
        color: $yellow;
        font-weight: bolder;
      }
    }
    a {
      color: $yellow;
      font-weight: bolder;
    }
  }
}

@import "../../styles/variables.scss";

.dropdown-tree {
  display: flex;
  justify-content: center;
  border: 0;
  background: transparent;
  .k-clear-value {
    visibility: hidden;
    width: 0 !important;
  }
  .k-picker,
  .k-focus,
  .k-picker.k-focus {
    border: 0;
    box-shadow: none;
    background: transparent;
  }
  .k-picker-md .k-input-inner {
    padding: 0.2vw;
    font-size: 0.7vw;
    justify-content: flex-end;
    overflow: visible;
  }
}

.country-item {
  padding: 0;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 0.5vw;
  }
  padding-bottom: 0.4vh;
}
.childItem {
  display: flex;
  align-items: center;
  padding-left: 0.5vw;
  padding-bottom: 0.4vh;
  .icon {
    margin-right: 0.5vw;
  }
}

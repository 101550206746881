// --------------------------------------------------------------------
// DESCRIPTION
// This is the global scss file which monitors the font sizes as per screen resolutions and sets css properties globally.
// Font sizes and Widths -> VW
// Borders -> PX
// Margins & Paddings -> %
// --------------------------------------------------------------------

// Imports
@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");

// Setting the common global styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primary;
}

body {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-color: white;
  zoom: 100%;
  transform: scale(1);
}

html {
  font-size: 1vw;
  zoom: unset;
}
// Setting the font sizes
h1 {
  font-size: $h1;
  margin: 0 2%;
}
h2 {
  font-size: $h2;
  margin: 0 2%;
}
h3 {
  font-size: $h3;
  margin: 0 2%;
}
h4 {
  font-size: $h4;
  margin: 0 2%;
}
h5 {
  font-size: $h5;
  margin: 0 2%;
}
h6 {
  font-size: $h6;
  margin: 0 2%;
}
p {
  font-size: $p;
  margin: 0 2%;
}
span {
  font-size: 0.7vw;
  margin: 0 2%;
}

// Utilities
.p-0 {
  padding: 0;
}
input[type="radio"] {
  width: 1vw;
  height: 1vw;
  border-width: 0.5vw;
  margin-top: 0;
}

.container-fluid {
  padding: 0;
}
.container {
  padding: 0;
}
.row {
  margin: 0;
}
.required:after {
  content: " *";
  color: $red;
  font-size: larger;
}

// ::-webkit-scrollbar {
//   display: none; /* Safari and Chrome */
// }

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  img {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 5vw;
  }
  #text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2vw;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

.k-filtercell-operator {
  display: none;
}

.user-onboarding-dropdown {
  .k-picker {
    .k-i-arrow-s {
      background: url(https://wsmanalytics-dev.azurewebsites.net/static/media/arrow-chevron-down-with-left-border.573e2f8….svg);
      background-repeat: no-repeat;
      background-position: left;
      background-size: 1.2vw;
      padding-right: 1.7vw;
    }
  }
}

hr {
  padding: 0.2vw;
  margin: 0.2vw;
}

::-webkit-scrollbar {
  display: none;
}

.title {
  margin: 1% 0% 0.5%;
  color: #404040;
  text-transform: capitalize;
  font-size: 1vw;
  font-weight: bold;
  padding-left: 2vw;
}
.header-button-container {
  display: flex;
}
.ship-to-link-btn {
  box-sizing: unset;
  font-size: $fontSize_0_65 !important;
  color: darkblue !important;
  border: 0;
  background-color: white;
  text-decoration: none;
  span {
    font-size: 0.65vw;
    color: darkblue !important;
    text-decoration: none !important;
  }
  .k-button-solid-base:hover,
  .k-button-solid-base.k-hover {
    background-color: none;
    text-decoration: underline;
  }
  .k-button:hover,
  .k-button:focus {
    box-shadow: none;
  }
}
.ship-to-link-btn :hover {
  text-decoration: underline;
}

.shell-tabs .shell-tabs-tab-btn:focus  {
  border: none !important;
}

.shell-tabs-content {
  border: 0.1vw solid rgba(0, 0, 0, 0.2);
}


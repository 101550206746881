@import "../../styles/variables.scss";

.dropbtn {
  background-color: transparent;
  color: white;
  padding: 1%;
  margin: 0;
  font-size: 1.2vw;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}

button:disabled,
button[disabled]{
  color: #8d8d89 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 1.5vw;
  background-color: #66c5d2;
  min-width: 4vw;
  max-height: 10vw;
  width: 8vw;
  height: justify;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.option-value {
  padding: 0.2vh 0;
}
.dropdown-content option {
  text-align: left;
  color: $white;
  padding: 4%;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;
  border-left-width: 0;
  border-right-width: 0;
}

.dropdown option:hover {
  background-color: $grey;
}

.show {
  display: block;
}

.dropdown-chevron-down-icon {
  height: 1em;
  width: 1em;
  padding-top: calc(1vw * 0.3);
}

.about-container {
  height: calc(100vh - 4vw);
  margin-top: 4vw;
  background: white;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      width: 4vw;
      margin-top: 4vw;
    }
    h1 {
      margin-top: 1vw;
      font-weight: 600;
      font-size: 2vw;
    }
    p{
      text-align: center;
      margin-top: 1vw;
      font-size: 1.4vw;
      width: 50%;
      color: #5a5a5a;
    }
  }
}

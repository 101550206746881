@import "../../styles/variables.scss";

.profile-popup-container {
  display: flex;
  margin-top: 10%;
  justify-content: flex-end;
}

.profile-loader {
  display: flex;
  .k-loading-mask,
  .k-loading-image,
  .k-loading-color {
    width: 25vw;
    left: 74vw;
    height: 20vh;
  }
  .loading-image {
    left: 80%;
  }
}
.profile-popover {
  display: flex;
  margin-top: 1vh;
  .childItem {
    padding: 0.2vh 1vw;
    display: flex;
    align-items: center;
  }
  
}
.profile-items {
  display: flex;
  flex: 1;
  position: absolute;
  flex-direction: column;
  width: 12.2vw;
  background: white;
  margin-top: 1.5vh;

  .shell-menu-label .shell-menu-label-text {
    overflow: visible !important;
  }
  .shell-menu-item {
    padding: 0 0.3vw !important;
  }

  .default-country-menu-item-dropdown {
    max-width: 5vw;
    .childItem {
      margin: 2vh 1vw;
      display: flex;
      align-items: center;
      .child-title {
        font-size: 0.8vw;
      }

      .icon {
        margin-right: 0.5vw;
      }
    }
  }

  .profile-menu {
    font-size: 0.7vw;
    padding: 0 0.5vw;
    width: 100%;
  }
  .dqssoi {
    width: 100%;
    justify-content: flex-start;
  }
  .eTDyWB {
    width: 100%;
    justify-content: flex-start;
  }
}
.msip-charts-custom-tooltip {
  background-color: #fff;
  padding: 5px 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.msip-hdr-profile-dp {
  display: flex;
  padding: 20px;
  min-width: 250px;
  align-items: center;
}

.msip-hdr-profile-dp-div {
  width: 100%;
  padding-top: 3vh;
  display: flex;
  flex-direction: column;
}

.msip-hdr-profile-dp-font {
  font-size: 11px !important;
  line-height: 16px !important;
}

.msip-hdr-profile-dp-color-grey {
  color: rgb(117, 117, 117) !important;
}
.msip-hdr-profile-divider {
  margin: 10px 0px 10px 0px;
  width: 100%;
  border-top: 1px solid rgba(28, 28, 28, 0.14);
}

.app-navbar {
  display: flex;
  width: 100%;
  height: 7.5vh;
  box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  z-index: 10;
  .nav-bar {
    display: flex;
    height: 7.6vh;
  }
  .brand-name {
    font-weight: bolder;
    cursor: pointer;
    color: $black;
    display: flex;
    align-self: center;
    flex: 1;
  }
  .menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .shell-menu-item {
    display: flex;
  }
  .about-item {
    display: flex;
    align-items: center;
    padding: 1vh 0.5vw;
    white-space: nowrap;
  }
  .dashboard-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0;
    padding-right: 1vw;
  }
  .link-icon {
    width: 1.2vw;
    padding: 0.1vh;
  }
  .link-text {
    font-weight: lighter;
    cursor: pointer;
    font-size: 0.7vw;
    padding: 0;
    padding-left: 0.5vw;
  }
  .profile-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    white-space: nowrap;
  }

  .links-section {
    height: inherit;
    padding: 0 2%;
    .links-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      height: inherit;
      .link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        color: $black;
        flex: 0.12;
        margin: 0 2%;
        .link-icon {
          width: 0.8vw;
        }
        .profile-pic {
          width: 2.2vw;
        }
        .link-text {
          font-weight: lighter;
          cursor: pointer;
          margin-top: 10%;
        }
      }
    }
  }
}
.mdm--popup-options-container {
  left: 92.7vw !important;
  .pop-content {
    width: 5vw;
    background: #66c5d2;
    .pop-content-ul {
      list-style: none;
    }
    a {
      z-index: 2;
      text-align: left;
      color: #fff;
      padding: 4%;
      text-decoration: none;
      width: 100%;
      cursor: pointer;
      border-left-width: 0;
      border-right-width: 0;
      &:hover {
        background-color: #0092a5;
      }
    }
    li {
      text-align: left;
      color: #fff;
      padding: 0% 0% 0% 6%;
      text-decoration: none;
      width: 100%;
      cursor: pointer;
      border-left-width: 0;
      border-right-width: 0;
      &:hover {
        background-color: #0092a5;
      }
    }
  }
}

@import "../../styles//variables.scss";

.data-grid {
  .k-command-cell {
    button {
      border: none;
    }
    .edit-btn {
      background-color: $yellow;
      color: $black;
    }
  }
  .buttons-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 1% 0;
    font-size: 0.8vw;
    font-weight: bold;
    .button {
      padding: 0.5vh 1vw;
      outline: none;
      border: none;
      border-radius: 0;
    }
    .edit-button {
      background-color: $yellow;
    }
    .data-grid-save-button {
      background-color: $yellow;
      margin-left: 1vw;
    }
    .data-grid-cancel-button {
      background: $lightGrey;
    }
  }
  .k-input-md .k-input-inner {
    padding: 1% 2%;
  }

  .k-icon-button.k-button-md {
    border-radius: 0;
    font-size: 0.5vw;
  }
}

.cell-link {
  color: $blue !important;
  font-weight: bold;
}

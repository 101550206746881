@import "../../../../styles/variables.scss";

.duplicate-deliveries-grid-container {
  display: flex;
  justify-content: center;
  overflow-y: visible;
  flex-direction: column;

  /* width */
  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $blue;
  }

  .delivery-cell-container {
    padding: 5px;
  }
  .k-checkbox-label {
    font-weight: 200;
    font-size: 12px;
    font-family: $primary;
    padding: 0.1vh;
    padding-top: 0.4vh;
  }
  .k-checkbox {
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    border-color: gray;
    align-self: center;
    justify-content: center;
  }
  .k-grid th
  {
    padding-left: 0.5vw !important;
  }
  .k-grid th,
  .k-grid td {
    padding: 0.5vh 0.1vw;
    font-weight: 200;
    font-size: 0.7vw;
  }
  .update-btn {
    background: $yellow !important;
    :hover {
      background-color: $yellow;
    }
  }
  .ignore-btn {
    margin-left: 1vw;
    background: $green !important;
    :hover {
      background-color: $green;
    }
  }
}

@import "../../../styles/variables.scss";
.warningIcon {
  display: flex;
  height: 1.5vh;
}
.send-icon {
  padding: 0.1vh;
}
.retry-icon-img {
  padding-top: 0.5vh;
}
.action-cell-icon-container {
  padding-left: 1vw !important;
  .action-button {
    border: 0;
    background: transparent;
    max-height: 3vh !important;
  }
}
.add-cell-container {
  gap: 0.2vw;
  justify-content: center;
  align-items: center;
  .add_tg-btn {
    padding: 0.3vh 0.1vw;
    color: white;
    background-color: #198754;
  }
}

.dependentContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  .dependentIndicator {
    background-color: $red;
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 1vw;
    margin-left: 0.5vw;
  }
}
.errorIcon {
  display: flex;
  height: 1.8vh;
}

.total-count-container {
  display: flex;
  justify-content: flex-end;
  font-size: 0.7vw;
  padding: 0.2vh 0 0 0;
  .message {
    padding-left: 3vw;
  }
}

.totalCount {
  font-weight: bold !important;
  text-align: left;
}

.boxContainer {
  display: flex;
  flex-direction: row;
  margin-right: 4vw;
  box-shadow: 1px 1px 5px 3px #e1e1e1;
  min-width: 10vw;
  height: fit-content;
  align-items: center;
  .box-title {
    white-space: normal;
    padding: 0.3vw 0.5vw;
  }
  .box-count {
    white-space: normal;
    padding: 0.3vw 0.5vw;
    flex: 1;
    text-align: end;
    font-weight: bold;
  }
  span {
    font-size: 0.8vw;
    margin: 0;
  }
  .highlighter {
    background-color: $darkBlue;
    width: 10px;
    align-self: normal;
  }
}
@import "../../styles/variables.scss";

.profile-page {
  height: calc(100vh - 4vw);
  margin-top: 4vw !important;
  .user-details-container {
    .user-details-table-container {
      margin-top: 8%;
      table {
        width: 80%;
        td {
          font-size: 0.8vw;
          padding: 1%;
          .date-picker {
            width: 25%;
            margin-right: 1%;
            margin-top: 1%;
          }
        }
      }
      .dropdownList {
        display: flex;
        width: 5vw;
      }
      .btn-container {
        padding: 1vw;
        .logout-btn {
          color: #fff;
          background: #ff5252;
          border: none;
          width: 5vw;
          font-size: 0.8vw;
          text-align: center;
          border-radius: 0.5vw;
        }
        .home-btn {
          color: #000;
          background: $yellow;
          border: none;
          width: 5vw;
          font-size: 0.8vw;
          text-align: center;
          border-radius: 0.5vw;
          margin-right: 1vw;
        }
      }
    }
    .user-details-heading {
      font-size: 2vw;
      margin: 0;
      padding-bottom: 2%;
    }
    .country-container {
      display: flex;
      flex-direction: row;
      margin: 1vw;
      .country-label {
        font-size: 0.8vw;
        font-weight: bold;
        margin-right: 2vw;
      }
    }
    .user-details-img-container {
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin-top: 8%;
      align-items: center;
      .user-img {
        width: 5vw;
        height: 5vw;
        margin-bottom: 5%;
        border-radius: 10vw;
      }
    }
    margin-top: 2%;
    height: calc(100% - 4vw);
    width: calc(100% - 4vw);
    background: $white;
    border: 0.2vw solid $blue;
    border-radius: 0.8vw;
  }
}

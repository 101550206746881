@import "../../../../../styles/variables.scss";
.issue-log-screen {
  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }
  height: 100%;
  .title {
    margin: 1% 2%;
  }
  .k-grid-content {
    background-color: #ffffff;
    overflow-x: hidden;
  }
  .k-grid-norecords-template {
    background-color: #ffffff;
    border: none;
    font-size: 0.7vw;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  .k-grid td {
    background-color: white;
    border: none;
    border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
  }
  .k-grid .k-grid-header .k-table-th {
    border: 0;
  }
  .k-grid tr {
    border-bottom: 0.1vh solid rgba(0, 0, 0, 0.1) !important;
  }
  .k-grid-content table,
  .k-grid-content-locked table {
    table-layout: fixed;
    border-collapse: collapse;
  }
  .add-issue-btn {
    margin-left: 2%;
    margin-bottom: 1%;
    background: $yellow !important;
    :hover {
      background-color: $yellow !important;
    }
  }
  .editableCell {
    border-bottom: 0;
  }
  .k-grid th:first-child {
    border-bottom: 0;
  }
  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    padding: 0.2vw;
    font-size: 0.7vw;
  }
  .k-customHeader {
    font-weight: bold;
    font-size: $fontSize_0_65;
    margin-top: 0.6vh;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-family: $primary;
    cursor: pointer !important;
  }
  .k-customHeader:hover {
    cursor: pointer !important;
  }
  .buttonsContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border: 0.1vw solid rgba(0, 0, 0, 0.2);
    padding: 0 0 0.5vh 0;
    border-top: 0;
    border-bottom: 0;

    .btn-primary {
      margin: 0 1vw;
      background: $yellow;
      span {
        font-size: 0.7vw;
        margin: 0 4%;
      }
    }

    .exportButton {
      margin: 0 1vw;
      span {
        font-size: 0.7vw;
        margin: 0 4%;
      }
    }
  }

  .submitButtonContainer {
    display: flex;
    align-items: flex-end;
    margin: 1vh 1.7vw 0;
    .k-button {
      font-size: 0.7vw !important;
      margin: 0 0.2vw;
    }
    .toolbar-button {
      margin: 0 0.2vw;
      width: 4.5vw;
    }
  }
  .header-container {
    display: flex;
    flex: 1;
    margin-left: 1.5vw;
    align-items: flex-end;
    .filters-container {
      margin: 1vw 0;
      display: flex;
      flex-direction: row;
      .k-chip-label {
        white-space: normal;
        text-overflow: clip;
        display: contents;
      }
      .k-chip-md .k-chip-action {
        padding: 0 !important;
        margin: 0 !important;
      }
      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.3vh;
        font-weight: bold;
        font-size: $fontSize_0_75;
      }
      span {
        font-size: 0.65vw;
        padding-right: 0;
        padding-left: 0;
        border-radius: 0.2vw;
      }
      .k-input-md .k-input-values {
        margin-right: 0.8vw;
      }
      .k-daterangepicker {
        margin-right: 0.8vw;
      }
      .k-input {
        font-size: 0.7vw;
        margin-right: 0.8vw;
        .k-clear-value {
          display: none;
        }
      }
      .filter {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        min-height: 0;
        .k-picker {
          min-height: 0 !important;
        }
      }
      .filter-date {
        .k-daterange-picker *,
        .k-daterangepicker * {
          max-width: 7.5vw;
        }
      }
      .filter-column {
        margin-left: 0.5vw;
        margin-right: 0.1vw;
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
      }
    }
  }
  .k-column-title {
    font-size: 0.7vw;
  }
  .k-table-md .k-table-td {
    font-size: 0.65vw;
    vertical-align: middle;
    padding: 1vh !important;
  }
  .filter-button {
    margin-left: 0.2vw;
    padding-bottom: 0vh;
    margin-right: 0;
    align-self: flex-end;
    .get-data-btn {
      padding: 0.2vw 1vw;
      background: $yellow;
      span {
        font-size: 0.7vw;
      }
    }
  }

  .loader-container {
    position: absolute;
    top: 40%;
    margin-top: -10vh;
    margin-left: -5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .loader-description {
      color: #868282;
    }
  }
  .issue-logs-container {
    width: 100%;
    min-height: 61vh;
    overflow: hidden;
    padding: 0 2%;
    .loader-screen {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
    }
    .no-issue-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 10vw;
    }
    .issue-table {
      width: inherit;
      overflow: scroll;
      font-size: 0.8vw;
      .table-header {
        .header-row {
          background-color: #eaeaea;
          td {
            width: 5vw;
            padding: 1% 2%;
            font-weight: bold;
          }
        }
      }
      .table-body {
        :hover {
          background-color: #eaeaea;
        }
        .issue-row {
          td {
            padding: 0.5% 2.5%;
            .update-btn {
              background: $yellow !important;
              :hover {
                background-color: $yellow;
              }
            }
          }
        }
      }
    }
  }
}

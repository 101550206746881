@import "../../styles/variables.scss";

.tour-container {
  height: calc(100vh - 2vw);
  margin-top: 5vh;
  background: white;
  .top-content {
    display: flex;
    padding-top: 6vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5vw;
    font-size: 1.2vw;
    font-weight: bold;
    img {
      width: 4vw;
    }
  }
  .tour-item-content {
    display: flex;
    align-items: center;
    padding: 1vh 0;
    font-size: 1vw;
  }
  .tour-scroll-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .k-scrollview-prev,
    .k-scrollview-next {
      color: $white;
    }

    .k-scrollview-prev:hover,
    .k-scrollview-next:hover {
      color: $darkBlue;
    }

    .tour-scroll {
      display: flex;
      width: 70vw;
      height: 82vh;
      border: none;
      .tour-scroll-image {
        width: 70vw;
        height: 72vh;
      }
    }
  }
}

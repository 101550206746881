@import '../../styles/variables.scss';

.page-not-found {
  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100vh;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    h1{
        font-size: 4vw;
    }
  }
  a {
    font-size: 3rem;
    color: $yellow;
  }
}

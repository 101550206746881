$border-radius: 0.5vw;
$primary: #ffd500;
$secondary: #ed1c24;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #d51923;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ffd500;
$selected-text: #ffffff;
$selected-bg: #ffd500;
$kendo-button-text: #424242;
$kendo-button-bg: #f5f5f5;
$link-text: #383838;
$link-hover-text: #fdce3e;
$series-a: #4115e0;
$series-b: #ffe162;
$series-c: #4cd180;
$series-d: #4b5ffa;
$series-e: #ac58ff;
$series-f: #ff5892;

@import "./theme.scss";

@import "../../../../styles/variables.scss";

.no-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 5vh;
}
.report-table {
  width: inherit;
  overflow: scroll;
  font-size: 0.8vw;
  margin-bottom: 2vh;
  .table-header {
    .header-row {
      background-color: #eaeaea;
      td {
        width: 5vw;
        padding: 1% 1.5%;
        font-weight: bold;
        border: 0.5pt solid $grey;
      }
    }
  }
  .table-body {
    :hover {
      background-color: #eaeaea;
    }
    .report-row {
      td {
        padding: 0.5% 1.5%;
        border: 0.5pt solid $grey;
        .update-btn {
          background: $yellow !important;
          :hover {
            background-color: $yellow;
          }
        }
      }
    }
  }
}

@import "../../../../styles//variables.scss";

.pre-posting-activities-container {
  background: #fff;
  margin: 0%;
  padding: 2%;
  height: calc(100% - 1vw);
  .heading-container {
    h3 {
      margin: 0;
    }
  }
  .data-grid {
    .email-btn-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .button {
        padding: 1%;
        outline: none;
        border: none;
        border-radius: 0;
        width: 17.5vw;
      }
      .email-btn {
        background-color: $yellow;
      }
    }
  }
  .date-picker {
    width: 25%;
    margin-right: 1%;
    margin-top: 1%;
  }
  .yellow-btn {
    margin-top: 1%;
    background: #ffd500;
  }
  .description {
    color: #727272;
  }
  .invalid-resolution-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 25%;
  }
}

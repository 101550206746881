@import "../../styles/variables.scss";

.dashboard-page {
  height: calc(100vh - 4vw);
  margin-top: 4vw !important;
  .summary-panel {
    h1 {
      line-height: 3vw;
    }
    h6 {
      line-height: 1.5vw;
      margin-top: 4%;
    }
    height: calc(100vh - 4vw);
    color: $white;
    padding: 3% 4%;
    margin: 0;
    background: #009eb4;
    overflow: auto;
    .gauge-container {
      margin: 0;
      margin-top: 5%;
      visibility: hidden;
      .gauge-chart-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        .leaks-selection-dropdown {
          background: transparent;
          border: none;
          font-size: 1.2vw;
          margin: auto 2%;
          padding: 0 5%;
          width: 12vw;
          option {
            background: $blue;
            color: $white;
          }
        }
      }
      .gauge-list-container {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 19%;
        ul {
          margin: 0;
          padding: 0;
        }
        .list {
          list-style: none;
          width: 80%;
          .list-item {
            display: flex;
            width: 75%;
            align-items: center;
            justify-content: flex-end;

            .list-item-count {
              font-size: 2vw;
            }
            .list-item-text {
              font-size: 0.8vw;
            }
          }
        }
      }
    }
  }

  .tiles-panel {
    height: calc(100vh - 4vw);
    padding: 2%;
    margin: 0;
    overflow: auto;
    .tiles-panel-container {
      width: 60vw;
      .tile-row {
        padding: 2% 0;
        .tile {
          width: 14vw;
          height: 11vw;
          background-color: $blue;
          border-radius: 0.5vw;
          cursor: pointer;
          .header {
            display: flex;
            align-items: center;
            border-top-right-radius: 0.5vw;
            border-top-left-radius: 0.5vw;
            padding: 0 4%;
            height: 4vw;
            background: #bbe2ea;
            color: $darkBlue;
          }
          .body {
            padding: 5%;
            p {
              font-size: 0.6vw;
            }
          }
        }
      }
    }
  }
}

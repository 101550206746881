@import "../../../../styles/variables.scss";

.user-management-container {
  .data-grid .k-input-md .k-input-inner {
    padding: 2% 2%;
    font-size: 0.65vw;
  }

  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }

  .buttonsLeftContainer {
    display: flex;
    justify-content: flex-end;
    .exportButton {
      margin: 0.7vh 0;
      background: $yellow;
      align-self: end;
      span {
        font-size: 0.7vw;
        margin: 0 2%;
      }
    }
  }

  .user-management-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin: 0 1vw;
    .add_tg-btn {
      padding: 0.3vh 0.5vw;
      color: white;
      background-color: #198754;
      margin-right: 1vw;
    }
    .exportButton {
      background: $yellow;
      align-self: end;
      span {
        font-size: 0.7vw;
        margin: 0 2%;
      }
    }
  }
  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }
  .content-container {
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0;
      .title {
        margin: 2% 1% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 0.7vw;
        font-weight: bold;
      }
    }
  }

  .data-grid-container {
    padding: 1vh 1vw;
    box-shadow: none;
    overflow-y: auto;

    .k-list-item.k-selected {
      padding-left: 0.3vw;
    }

    .k-pager-sizes .k-dropdownlist,
    .k-pager-sizes > select {
      width: 5em;
      height: 1.7vw;
      margin: 0.2vw;
    }
    .k-grid-pager {
      padding: 0.2vh;
    }
    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }
    .k-pager-info {
      font-size: 0.7vw;
      margin-right: 1vw !important;
    }
    .k-pager-sizes {
      span {
        font-size: 0.7vw;
        margin: 0 2%;
      }
    }
    button:focus {
      box-shadow: none !important;
    }
    .k-expander.k-focus {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.03);
    }

    .k-grid {
      border-color: rgba(0, 0, 0, 0.01);
    }
    .k-button-link {
      color: darkblue !important;
      border: 0;
      font-size: 0.5vw;
      padding: 0.1vw;
      span {
        font-size: 0.65vw;
      }
    }
    .k-grid th:first-child {
      border: 0;
      cursor: pointer;
    }
    .k-grid td {
      padding: 0.6vh 0 0.6vh 0.6vw !important;
      font-size: $fontSize_0_65;
      background-color: white;
      border: none;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
    }
    .warningIcon {
      display: flex;
      height: 1.5vh;
    }
    .commentsIconContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .userIconContainer {
        display: flex;
        margin-left: 0.3vw;
        height: 0.5vh;
        align-items: center;
      }
      .userIcon {
        display: flex;
        height: 1.5vh;
      }
      .userWarningIcon {
        display: flex;
        height: 1.9vh;
      }
    }

    .emptyItem {
      display: flex;
      height: 1.5vh;
      width: 1vw;
    }
    .errorIcon {
      display: flex;
      height: 1.8vh;
    }
    .totalClass {
      font-weight: bold;
    }
    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_60;
      margin-top: 0.3vh;
    }
    .k-table-td {
      font-size: $fontSize_0_60;
      overflow-wrap: anywhere;
      padding: 8px 0px 8px 4px !important;
    }
    .commentsContainer {
      padding-top: 1.5vh !important;
      padding-bottom: 1.5vh !important;
    }
    .k-table-md .k-table-th {
      padding: 1vh 0.3vw 0 !important;
      margin: 0;
      border: 0;
      .k-grid th {
        border: 0 !important;
      }
      .dateHeader {
        font-size: $fontSize_0_60;
        font-weight: bold;
        display: flex;
        padding: 1vh 0 0;
        margin-bottom: 0.3vh;
        .comments-icon-container {
          display: flex;
          flex-direction: row;
        }
        .missingValue {
          color: white;
          font-size: 0.6vw;
          margin-top: 0.5vh;
          margin-right: 0.5vw;
          background: $red;
          width: -moz-fit-content;
          width: fit-content;
          padding: 0.2vw 0.3vw;
          border-radius: 1vw;
          margin-left: 0.5vw;
        }
        .blankValue {
          color: white;
          font-size: 0.6vw;
          margin-top: 0.5vh;
          margin-right: 0.5vw;
          background: $darkBlue;
          width: -moz-fit-content;
          width: fit-content;
          padding: 0.2vw 0.3vw;
          border-radius: 1vw;
          margin-left: 0.2vw;
        }
      }
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_60;
        margin-top: 0.3vh;
        display: flex;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-grid tr {
      border-bottom: 0.1vh;
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 0.1vh !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
  }

  .grid-tank-view {
    .k-grid td {
      font-size: $fontSize_0_75;
    }
    .k-column-title {
      font-size: $fontSize_0_70;
    }
    .k-table-td {
      font-size: $fontSize_0_70;
    }
  }
  .tab-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    .modeContainer {
      display: flex;
      flex: 1;
      align-items: end;
      .k-button-solid-base {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        span {
          font-size: 0.7vw;
        }
      }
      .k-button-solid-base.k-selected {
        border-color: $lightBlueShadeDark;
        color: $black;
        background-color: $blue;
      }
    }

    .switchContainer {
      display: flex;
      flex-direction: row;
      width: -webkit-fill-available;
      align-items: center;
      justify-content: end;
      padding-bottom: 1vh;
      .switch-label {
        font-size: 0.65vw;
        margin: 0;
      }
      .k-switch-sm {
        margin: 0 1vw;
      }
      .k-switch-on .k-switch-track {
        border-color: $red;
        color: black;
        background-color: $red;
      }
    }
  }

  .k-chip-label {
    display: flex;
    text-overflow: clip;
    flex: 1 1 auto;
    margin: 2px;
    white-space: normal;
    overflow: visible;
  }

  .gridToolbar {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    border-right: 0.1vw solid rgba(0, 0, 0, 0.2);
    border-left: 0.1vw solid rgba(0, 0, 0, 0.2);
    border-top: 0.1vw solid rgba(0, 0, 0, 0.2);

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin: 1vw 0.2vw;
      .filters-container {
        display: flex;
        flex-direction: row;
        margin-right: 0;

        .k-chip-md {
          font-size: 14px;
          line-height: 1;
          margin: 0.2vh 0;
        }
        .k-chip-actions {
          width: 0.6vw;
          .k-svg-icon > svg {
            width: 0.7vw;
          }
        }

        .k-input-md .k-input-values,
        .k-picker-md .k-input-values {
          width: 100%;
          padding: 0 0.1vw;
          display: block;
          gap: 2px;
          cursor: text;
          .k-input-inner {
            padding: 0.2vh 0.2vw;
          }
          .k-icon-button.k-button-md {
            font-size: 0.5vw;
            padding: 0.2vh 0.2vw;
          }
        }
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 0.3vh;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
        span {
          font-size: 0.65vw;
          margin-right: 0;
          width: fit-content;
          padding-left: 0;
          padding-right: 0.1vw;
        }
        .k-input {
          padding-right: 1.2vw;
          font-size: 0.7vw;
          .k-clear-value {
            display: none;
          }
        }
        .filter {
          margin-left: 0.5vw;
          margin-right: 0.1vw;
        }
        .filter-date {
          margin-left: 0.5vw;
          .k-input {
            padding-right: 0;
            font-size: 0.7vw;
            .k-clear-value {
              display: none;
            }
          }
          span {
            width: inherit;
          }
          label {
            display: flex;
            font-weight: bold;
            font-size: 0.65vw;
          }
          .k-daterange-picker *,
          .k-daterangepicker * {
            max-width: 6vw;
            margin: 0.07vh;
            font-size: 0.7vw;
          }
          .k-icon-button.k-button-md {
            margin: 0 0.5%;
            border-radius: 0;
            border-width: 0.1vw;
            background: #66c5d2;
            padding: 0.3vw 0.6vw;
          }
        }
        .filter-column {
          margin-left: 0.5vw;
          margin-right: 0.1vw;
          label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: $fontSize_0_60;
          }
        }
      }
    }
    .rowContainer {
      display: flex;
      flex: 1;
      justify-content: space-between;
      .buttonsContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        .blankCount {
          align-self: end;
          padding: 1vh;
        }
        
      }

      .submitButtonContainer {
        display: flex;
        align-items: end;
        margin: 0.5vh 0.5vw;
        height: 3.2vh;
        .k-button {
          font-size: 0.7vw !important;
          margin: 0 0.2vw;
        }
        .toolbar-button {
          margin: 0 0.2vw;
          padding: 0.4vh 0.5vw;
          width: 4.5vw;
        }
        .refresh-btn {
          margin-left: 2vw;
          span {
            font-size: 0.7vw;
          }
        }
      }
      .rightContainer {
        .countContainer {
          display: flex;
          margin: 0;
          .counter {
            width: 3.2vw;
            height: 1.5vw;
            background-color: $paleGrey;
            font-size: $fontSize_0_60;
            text-align: center;
            margin: 0.2vw 0;
            padding: 0.4vw 0vw;
            border-right: 0.05vw solid $lightGrey;
          }
        }
        .labelContainer {
          display: flex;
          margin: 0;
          .cumulativeContainer {
            display: flex;
            flex-direction: column;
            margin: 0;
          }

          .labelFlag {
            width: 3.2vw;
            height: 3vw;
            background-color: $lightGrey;
            font-size: $fontSize_0_55;
            padding: 0.4vw 0.1vw;
            margin: 0;
            border: 0.1vw solid $black;
            border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2);
            border-right: none;
          }
          .cumulativeFlag {
            width: 6.4vw;
            height: 1.25vw;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: $lightGrey;
            font-size: $fontSize_0_55;
            font-weight: bold;
            padding: 0.1vw;
            margin: 0;
            border: 0.1vw solid $black;
            border-bottom: none;
            border-right: 0.1vw solid rgba(0, 0, 0, 0.2);
          }
          .cumulativeVar {
            width: 3.2vw;
            height: 1.75vw;
            background-color: $lightGrey;
            font-size: $fontSize_0_50;
            padding: 0.1vw;
            margin: 0;
            border: 0.1vw solid $black;
            border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2);
            border-right: 0.1vw solid rgba(0, 0, 0, 0.2);
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@import "../../../../styles/variables.scss";

.tank-leaks-screen-container {
  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }
  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }
  .content-container {
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0;

      .title {
        margin: 2% 1% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 0.7vw;
        font-weight: bold;
      }
    }
  }

  .data-grid-container {
    padding: 0;
    border: 0.1vw solid rgba(0, 0, 0, 0.2);
    margin: 0 1vw;
    display: flex;
    flex-direction: column;

    .k-grid-content {
      background-color: #ffffff;
      overflow-x: hidden;
    }
    .k-grid-norecords-template {
      background-color: #ffffff;
      border: none;
      font-size: 0.7vw;
      display: flex;
      flex: 1;
      justify-content: center;
    }
    .k-table-th {
      padding-left: 0.5vw;
      margin: 0;
      border: 0;
    }
    .k-grid td {
      border: none;
      background: white;
      padding: 0.1vw 0.5vw 0.1vw 0.1vw;
      font-size: $fontSize_0_65;
      border-bottom: 0.05vw solid rgba(0, 0, 0, 0.2) !important;
      .k-button * {
        box-sizing: unset;
        font-size: $fontSize_0_65 !important;
        text-decoration: underline;
        color: $darkBlue;
        border: none;
      }
    }

    .k-grid th {
      border: 0 !important;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: anywhere;
      padding: 8px 12px !important;
    }
    .k-table-md .k-table-th {
      padding: 3px 8px 8px 8px !important;
      margin: 0;
      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        margin-top: 0.6vh;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-grid-pager {
      border-top: 0.1vw solid rgba(0, 0, 0, 0.2);
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }

  .gridToolbar {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    border: 0;
    .empty-date {
      border-color: rgba(245, 5, 5, 0.857);
    }
    .date {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .filter-button-container {
      margin: 0.2vw;
      margin: 1vh 1vw 0.3vh;
      .filter-button {
        margin: 0;
      }
      .export-button {
        margin: 0 1vw;
      }
    }
    .get-data-btn {
      margin-left: 0.2vw;
      padding: 0.2vw 1vw;
      background: $yellow;
      span {
        font-size: 0.7vw;
      }
    }
    .refresh-btn {
      padding: 0.2vw 1vw;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
      span {
        font-size: 0.7vw;
      }
    }
    .header-container {
      display: flex;
      flex: 1;
      justify-content: center;
      margin: 1vh 0.2vw 0.5vh;
      .filters-container {
        margin-right: 0;
        display: flex;
        flex-direction: row;
        .k-chip-label {
          white-space: normal;
          text-overflow: clip;
          display: contents;
        }
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 0.3vh;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
        span {
          font-size: 0.65vw;
        }
        .k-input {
          padding-right: 1.2vw;
          font-size: 0.7vw;
          .k-clear-value {
            display: none;
          }
        }
        .filter {
          margin-left: 0.5vw;
          margin-right: 0.2vw;
        }
        .filter-date {
          span {
            min-height: 3.2vh;
          }
          .k-daterange-picker *,
          .k-daterangepicker * {
            max-width: 7.5vw;
          }
        }
        .filter-column {
          margin-left: 0.5vw;
          margin-right: 0.1vw;
          label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: $fontSize_0_65;
          }
        }
      }
      .filter-button {
        margin: 0;
        padding-bottom: 0;
        margin-top: 27px;
      }
    }
  }
}

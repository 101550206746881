.content-window {
  visibility: unset;
  .k-dialog-titlebar {
    background-color: #66c5d2;
  }
}

@media only screen and (max-width: 768px) {
  .content-window {
    visibility: hidden;
  }
}

@import "../../styles/variables.scss";

.modeContainer {
  display: flex;
  flex: 1;
  align-items: top;
  justify-content: left;
  padding: 0.6vh 0.55vw 0;
  .tabItem {
    height: 0.4vh;
    background-color: transparent;
  }
  .tab-selected {
    display: flex;
    height: 0.4vh;
    margin-top: 1vh;
    background-color: $yellow;
  }
  .k-button-solid-base {
    span {
      font-size: 0.7vw;
    }
    border: 0;
    padding-bottom: 0;
    background: transparent;
    display: flex;
    align-self: flex-start;
  }
  .k-button-solid-base.k-selected,
  .k-button-solid-base:focus {
    border-color: transparent;
    color: #595959;
    background-color: transparent;
    box-shadow: none;
  }
}

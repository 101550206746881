.searchable-dropdown {
  .k-input-inner {
    align-items: baseline !important;
  }
  .k-input-value-text {
    overflow: visible !important;
    text-overflow: ellipsis !important;
    text-wrap: wrap;
    padding: 0.1vw;
  }
}

@import "../../../../styles/variables.scss";

.k-treeview-item {
  background: $white !important;
}

.tank-threshold-container {
  display: flex;
  flex-direction: column;
  padding: 0 0.5vw 2vh;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.15);
  height: 82%;
  overflow-y: auto;
  margin-top: 1vh;
  .delivery-cell-container {
    padding: 5px;
  }
  .header-container {
    display: flex;
    align-items: center;
    .filters-container {
      display: flex;
      flex: 1;
      margin: 2vh 0 0;
      flex-direction: row;
      justify-content: center;

      .filter-date {
        margin: 0;
        margin-left: 1vw;
        span {
          margin-right: 0.6vw;
          padding: 0.12vw;
          min-height: 3.2vh;
        }
        .k-daterange-picker *,
        .k-daterangepicker * {
          max-width: 7vw;
        }
      }

      .get-data-btn-change {
        padding: 0.2vw 1vw;
        background: $yellow;
        height: fit-content;
        align-self: end;
        margin: 2.5vh 2vw 1.2vh 2vw;
        span {
          font-size: 0.7vw;
        }
      }
      .get-data-btn-delivery {
        padding: 0.2vw 1vw;
        background: $yellow;
        height: fit-content;
        align-self: end;
        margin: 0.5vh 2vw 4.2vh 2vw;
        span {
          font-size: 0.7vw;
        }
      }

      .k-chip-label {
        white-space: normal;
        flex: 1 1 auto;
      }
      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0.3vh;
        font-weight: bold;
        font-size: $fontSize_0_65;
      }
      span {
        font-size: 0.65vw;
        margin-right: 0.3vw !important;
        padding-right: 0;
        padding-left: 0;
      }
      .k-chip-actions {
        width: 0.6vw;
      }
      .k-chip-solid-base {
        display: flex;
        flex: 0.8;
    }
      .k-input {
        padding-right: 0.2vw;
        font-size: 0.7vw;
        .k-clear-value {
          display: none;
        }
      }
      .filter {
        margin-left: 0;
        margin-right: 1vw;
      }
      .k-slider-horizontal {
        margin-top: 0.5vh;
        margin-left: -0.5vw;
        width: 11vw;
      }
      .k-draghandle {
        width: 1vw !important;
        height: 1vw !important;
      }
      .filter-row {
        display: flex;
        flex-direction: row;

        .k-input {
          width: 5vw;
          height: 1.8vw;
          border-radius: 0.2vw;
        }

        span {
          margin: 0;
          margin-right: 0.5vw;
          padding-right: 0;
        }
      }
      .filter-column {
        margin-left: 0.5vw;
        margin-right: 0.1vw;
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: $fontSize_0_60;
        }
      }
    }
    .filter-button {
      .get-data-btn {
        padding: 0.5vh 1vw;
        background: $yellow;
      }
    }
  }
}
.threshold-data-container {
  padding-top: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-data-label {
    min-width: 10vw;
  }
  .threshold-table-container {
    .threshold-header-label {
      display: flex;
      font-weight: 600;
      font-size: 22px;
      font-family: $primary;
      padding: 0.1vh;
      padding-top: 0.4vh;
      justify-content: center;
      padding-bottom: 1vh;
    }
  }
  .edit-threshold-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .threshold-label {
      font-weight: 600;
      font-size: 16px;
      font-family: $primary;
      padding: 0.1vh;
      padding-top: 0.4vh;
      min-width: 5vw;
      width: 200px;
      text-align: end;
    }
    .threshold-input {
      min-width: 5vw;
      max-width: 6vw;
      height: 2vw;
      font-size: 16px;
      font-weight: 500;
    }
    .edit-button {
      background: $yellow;
      width: 5vw;
      min-width: 5vw;
      margin: 0;
      margin-left: 1vw;
      padding: 0.6vh;
    }
    .cancel-button {
      background: $paleGrey;
      width: 5vw;
      min-width: 5vw;
      margin: 0;
      margin-left: 1vw;
      padding: 0.4vh;
    }
  }
}

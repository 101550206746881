@import "../../../../styles/variables.scss";  

.oot-summary-screen-container {
  padding: 0 1% !important;
  .k-expander {
    margin-bottom: 1vh;
    border-color: $lightBlue;
  }
  .k-expander.k-focus {
    box-shadow: none;
  }
  .k-expander-content {
    padding: 0.7vh 1.2vw;
  }
  .k-expander-title {
    color: $black;
    font-weight: bold;
    font-family: $primary;
    text-transform: capitalize;
  }
  .grid-container {
    display: flex;
    flex-direction: row;
    .count-grid-container {
      margin-bottom: 2vw;
    }
    ::-webkit-scrollbar {
      width: 0.3vw;
      display: block;
    }

    ::-webkit-scrollbar-thumb {
      background: $lightGrey;
      border-radius: 0.5vw;
    }
  }

  ::-webkit-scrollbar {
    width: 0.3vw;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: $lightGrey;
    border-radius: 0.5vw;
  }
  .loader-container {
    width: 100%;
    justify-content: center;
    height: 50vh;
    flex-direction: column;
  }
  .toggleContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin: 0;
    .switchContainer {
      display: flex;
      flex-direction: row;
      width: -webkit-fill-available;
      align-items: center;
      justify-content: flex-end;
      .switch-label {
        font-size: 0.65vw;
        margin: 0;
      }
      .k-switch-sm {
        margin: 0 1vw;
      }
      .k-switch-on .k-switch-track {
        border-color: $red;
        color: black;
        background-color: $red;
      }
    }
  }

  .materialLabel {
    background-color: $red;
  }
  .content-container {
    background: $white;
    overflow: hidden;
    .data-grid {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0;

      .title {
        margin: 2% 1% 1%;
        color: #404040;
        text-transform: uppercase;
        font-size: 0.7vw;
        font-weight: bold;
      }
    }
  }

  .data-grid-container {
    box-shadow: none;
    padding: 0.5% 0 0 0;
    display: flex;
    flex-direction: column;

    .k-grid {
      border-width: 0.06vw;
    }

    .investigation-summary-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .site-summary-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 5.3vh;
    }

    .grid-title {
      font-weight: bold;
      padding: 1.7vh 0 0;
    }
    .site-summary-grid-title {
      font-weight: bold;
      align-self: end;
      padding: 0.5vh 0;
    }

    .nonEditableCell {
      padding: 1.7vh 0.6vw !important;
    }
    .editableCell {
      padding: 0.9vh 0.5vh !important;
    }
    .investigation-grid {
      .k-grid-content tr:last-child > td,
      .k-grid-content-locked tr:last-child > tr {
        background: #e5e5e5 !important;
        border: 0;
        border-top: 0.05vw solid rgba(0, 0, 0, 0.2);
        font-weight: bold;
        height: 3.5vh;
        padding: 0 1vh;
        text-align: left !important;
      }
      width: 45vw;
    }
    .site-summary-container {
      width: 35vw;
      margin-right: 0.5vw;
    }

    .k-grid td {
      font-size: 0.65vw;
      padding-left: 0.5vw !important;
      .k-button * {
        box-sizing: unset;
        font-size: $fontSize_0_65 !important;
        text-decoration: underline;
        color: $darkBlue;
        border: none;
      }
      input.k-input {
        height: inherit;
        padding: 0.5vh;
        margin: 0.3vh;
        background-color: white;
        border-radius: 0.3vw;
        width: -webkit-fill-available;
        border: 0.05vw solid rgba(0, 0, 0, 0.5);
        font-size: 0.58vw;
      }
      .lastValue {
        font-size: $fontSize_0_65;
      }
    }
    .buttonsContainer {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5vw 0;
      .exportButton {
        margin: 0.5vh 0.5vw;
        background: $yellow;
        align-self: end;
        span {
          font-size: 0.7vw;
          margin: 0 2%;
        }
      }
    }

    .refresh-btn {
      margin: 0 1vw;
      span {
        font-size: 0.7vw;
      }
    }
    .export-btn {
      margin: 2vh 0 0.5vh;
      height: 2.7vh;
      span {
        font-size: 0.7vw;
      }
    }

    .submitButtonContainer {
      display: flex;
      align-items: flex-end;
      margin: 0.5vh 0.5vw;
      .k-button {
        font-size: 0.7vw !important;
        margin: 0 0.2vw;
      }
      .toolbar-button {
        margin: 0 0.2vw;
        width: 4.5vw;
      }
    }

    .k-grid th {
      border: 0 !important;
      padding: 8px;
    }

    .k-column-title {
      white-space: normal;
      font-weight: bold;
      font-size: $fontSize_0_65;
    }
    .k-table-td {
      font-size: $fontSize_0_65;
      overflow-wrap: anywhere;
      padding: 8px 12px !important;
    }
    .k-header > .k-cell-inner {
      margin: 0;
      background: lightgray;
    }
    .k-table-md .k-table-th {
      padding: 0 !important;
      margin: 0;
      border: 0;
      .k-cell-inner > .k-link {
        padding: 8px 8px;
        border: 0.1vh solid rgba(0, 0, 0, 0.2);
      }

      .k-customHeader {
        font-weight: bold;
        font-size: $fontSize_0_65;
        margin-top: 0.6vh;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-family: $primary;
        cursor: pointer !important;
      }
      .k-customHeader:hover {
        cursor: pointer !important;
      }
    }
    .k-header {
      vertical-align: top !important;
      padding: 0 4px !important;
    }
    .k-input-value-text {
      font-size: $fontSize_0_65;
      white-space: inherit;
    }
    .k-grid .k-grid-container {
      .k-input-value-text {
        white-space: normal !important;
      }
    }
    .k-filtercell {
      padding: 8px;
    }
    .k-input-inner {
      font-size: 0.7vw !important;
      font-family: $gridFont;
      padding: 4px;
    }
  }

  .modeContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .k-button-solid-base {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      span {
        font-size: 0.75vw;
      }
    }
    .k-button-solid-base.k-selected {
      border-color: $lightBlueShadeDark;
      color: $black;
      background-color: $blue;
    }
  }
  .empty-date {
    border-color: rgba(245, 5, 5, 0.857);
  }
  .date {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .materialLblContainer {
    font-size: 0.8vw;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    background-color: #e5e5e5;
    font-weight: bold;
    border: 0.1vw solid rgba(0, 0, 0, 0.2);
    border-bottom: 0;
  }
  .header-box-container {
    display: flex;
    flex: 1;
    margin: 1vh 5vh 2vh;
    justify-content: center;
    .boxContainer {
      display: flex;
      flex-direction: row;
      margin-right: 1vw;
      box-shadow: 1px 1px 5px 3px #e1e1e1;
      max-width: 15vw;
      align-items: center;
      .box-title {
        white-space: normal;
        padding: 0.3vw 0.5vw;
      }
      .box-count {
        white-space: normal;
        padding: 0.3vw 0.5vw;
        flex: 1;
        text-align: end;
      }
      span {
        font-size: 0.75vw;
        margin: 0;
      }
      .highlighter {
        background-color: $darkBlue;
        width: 10px;
        align-self: normal;
      }
    }
  }
  .header-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    .filters-container {
      margin-right: 8.5vw;
      margin-left: 0;
      display: flex;
      justify-content: left;
      flex-direction: row;
      align-items: center;
      .k-chip-label {
        white-space: normal;
        text-overflow: clip;
        display: contents;
      }
      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 0;
        padding-right: 0.5vw;
        font-weight: bold;
        font-size: 0.65vw;
      }
      span {
        font-size: 0.65vw;
      }
      .k-input {
        padding-right: 1.2vw;
        font-size: 0.7vw;
        height: 100%;
        .k-clear-value {
          display: none;
        }
      }
      .filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0.5vw;
        margin-right: 0;
        .k-chip-solid-base {
          height: 100%;
        }
        .k-picker {
          width: 6vw !important;
        }
      }
      .filter-date {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0.8vw 0 0.5vw;
        width: 8vw;
        .k-input {
          padding-right: 0;
          height: auto;
          width: 8vw;
          font-size: 0.7vw;
        }
      }
      .filter-column {
        margin-left: 0.5vw;
        margin-right: 0.1vw;
        label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: $fontSize_0_65;
        }
      }
    }
    .filter-button {
      margin-left: 0;
      margin-right: 0;
      align-self: center;
      .get-data-btn {
        padding: 0.2vw 0.5vw;
        background: $yellow;
        span {
          font-size: 0.7vw;
        }
      }
    }
  }
}
